import { useEffect, useState } from 'react';
import { Record, FileType } from 'types';
import { getDataBlob } from 'utils';

type ImageKeys = 'imageOne' | 'imageTwo' | 'imageThree' | 'imageFour' | 'imageFive';

export const useRecordFormImages = (defaultValues: Record | undefined) => {
  const [images, setImages] = useState<FileType[]>([]);

  useEffect(() => {
    const createFilesFromUrl = async () => {
      const files: Partial<FileType>[] = [];

      const imageKeys: ImageKeys[] = ['imageOne', 'imageTwo', 'imageThree', 'imageFour', 'imageFive'];

      for (const key of imageKeys) {
        const imageUrl = defaultValues?.[key];
        if (imageUrl) {
          const result = await getDataBlob(imageUrl as string); // Use await safely here
          const originalFileName = imageUrl.split('/').pop()?.split('?')[0] || 'unknown.pdf';
        const originalFileLastModified = defaultValues?.createdAt
        ? new Date(defaultValues.createdAt).getTime()
        : Date.now();

          const originalFile = new File([result], originalFileName, { 
            type: 'application/pdf', 
            lastModified: originalFileLastModified,
          });

          files.push({
            dataURL: imageUrl,
            file: result as File,
            originalFile, // Ensure originalFile is preserved
          });
        }
      }
      setImages(files as FileType[]);
    };

    createFilesFromUrl().catch(console.error);
  }, [defaultValues]);

  const onImageChange = (newImages: FileType[]): void => {
    setImages((prevImages) =>
      newImages.map((img, index) => ({
        ...img,
        originalFile: prevImages[index]?.originalFile || img.originalFile || img.file, // Retain originalFile
      }))
    );
  }
 
  return {
    images,
    onImageChange,
  };
};
