import { ComponentType, Dispatch, SetStateAction, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import ImagePlaceholder from 'assets/image-placeholder.jpg';
import { useClasses } from 'utils/hooks/useClasses';
import { toast } from 'react-toastify';
import { Car } from 'types';
import { SpinFeedLoaderMini } from 'components/SpinFeedLoader/SpinFeedLoader';

interface ImageUploaderProps {
  images: ImageListType;
  onChange: any;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  car: Car;
}

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    position: 'relative',
    marginBottom: spacing(2),

    '& img': {
      maxWidth: '100%',
    },
  },
  wrapper: {
    position: 'relative',
  },
  uploadedImage: {
    '&:hover img': {
      filter: 'brightness(0.6)',
    },
  },
  placeholder: {
    cursor: 'pointer',
  },
  placeholderImage: {
    filter: 'contrast(0.45)',
  },
  positionCentered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  deleteButtonWrapper: {
    textAlign: 'center',
    color: palette.text.hint,
  },
});

export const ImageUploader: ComponentType<ImageUploaderProps> = ({ images, onChange, setIsDirty, car }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const onError = (errors: any, files: any) => {
    if (errors.acceptType) {
      toast(t('image_uploader_error_unsupported_format'));
    }
  };
  const [carImageAdded, setCarImageAdded] = useState(true);

  const [shouldRenderIcon, setShouldRenderIcon] = useState(false);

  const renderIcon = () => {
    setShouldRenderIcon(true);
  };

  return (
    <div className={classes.root}>
      <ImageUploading acceptType={['jpg', 'jpeg', 'png', 'gif']} value={images} onChange={onChange} onError={onError}>
        {({ imageList, onImageUpload, onImageRemoveAll, dragProps, errors }) => (
          <div className={classes.wrapper}>
            {imageList.length > 0 ? (
              <div className={classes.uploadedImage}>
                {imageList.map((image, index) => (
                  <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={image.dataURL} alt='' onLoad={renderIcon} style={{ maxWidth: '100%', height: 'auto' }} />
                    {shouldRenderIcon && (
                      <div className={clsx(classes.positionCentered, classes.deleteButtonWrapper)}>
                        <IconButton
                          color='inherit'
                          onClick={() => {
                            onImageRemoveAll();
                            setIsDirty(true);
                            setCarImageAdded(false);
                          }}
                          size='large'
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : carImageAdded && car.image !== '' && car.image !== null ? (
              <SpinFeedLoaderMini loading={true} />
            ) : (
              <div onClick={onImageUpload} className={classes.placeholder} {...dragProps}>
                <img src={ImagePlaceholder} alt='Drag and drop' className={classes.placeholderImage} />
                <span className={classes.positionCentered}>{t('image_uploader_hint')}</span>
              </div>
            )}
            {errors?.acceptType && t('image_upload_modal_unsupported_format')}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
