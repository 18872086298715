import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Car } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('dashboard_form');

export interface BoardFilters {
  carId: string;
  year: number;
  category: string;
}

interface DashboardFiltersProps {
  cars?: Car[];
  years?: number[];
  filters: BoardFilters;
  onFiltersChange: (filters: Partial<BoardFilters>) => void;
  publicChart?: boolean;
}

const styles = ({ breakpoints, spacing }: Theme) => ({
  formControl: {
    marginBottom: spacing(2),
    '& select': {
      width: 'unset',
    },
    [breakpoints.up('md')]: {
      margin: spacing(1, 1, 2, 1),
      minWidth: 120,
    },
  },
  selectEmpty: {
    marginTop: spacing(2),
  },
  filterSelect: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(0.5, 1.5),
    color: 'white',
    fontSize: 14,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      borderColor: '#3B82F6',
    },
  },
});

export const DashboardFilters: React.FC<DashboardFiltersProps> = ({
  cars = [],
  years = [],
  filters,
  publicChart,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const handleChange = (key: keyof BoardFilters) => (event: SelectChangeEvent) => {
    const value = event.target.value;
    onFiltersChange({ [key]: key === 'year' ? Number(value) : value });
  };

  return (
    <Grid container spacing={2}>
      {!publicChart && (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small' className={classes.formControl}>
            <InputLabel htmlFor='car'>{t(tKey('car'))}</InputLabel>
            <Select
              size='small'
              id='car'
              value={filters.carId}
              onChange={handleChange('carId')}
              label={t(tKey('car'))}
            >
              {cars.map((car) => (
                <MenuItem key={car.id} value={car.id}>
                  {capitalize(car.make)} {car.model}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      
      {years && years.length > 0 && (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small' className={classes.formControl}>
            <InputLabel htmlFor='year'>{t(tKey('year'))}</InputLabel>
            <Select
              size='small'
              id='year'
              value={filters.year.toString()}
              onChange={handleChange('year')}
              label={t(tKey('year'))}
            >
              {years.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
