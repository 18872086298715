import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import { useClasses } from 'utils/hooks/useClasses';
import { useTranslation } from 'react-i18next';

// Define interfaces for type safety
interface Record {
  id?: string;
  category?: string;
  title?: string;
  cost?: string | number;
  mileage?: string | number;
  datePerformed?: string;
  dateCreated?: string;
  [key: string]: any;
}

interface EfficiencyDataPoint {
  date: string;
  efficiency: number | null; // Can be null for months with no data
  mileage: number;
  cost: number;
  month: string;
  monthNumber: string; // Will now store "Jan 24" format
  displayMonth: string; // Will store original "1-2024" format for backward compatibility
  hasData: boolean;     // Flag to indicate if this month has actual data
}

interface DateRange {
  startDate: string;
  endDate: string;
}

interface FuelEfficiencyChartProps {
  records: Record[];
  mileageMetric?: string;
  currency?: string;
}

// Define styles
const styles = ({ spacing, breakpoints }: { spacing: (arg0: number, arg1?: number) => number, breakpoints: any }) => ({
  root: {
    padding: spacing(3, 3), // Reduced padding
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(3),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: spacing(2),
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    marginBottom: spacing(3),
  },
  dateInputsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(2),
  },
  buttonsRow: {
    display: 'flex',
    gap: spacing(1),
  },
  dateInputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateLabel: {
    fontSize: '14px',
    marginBottom: '4px',
    display: 'block',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  dateInput: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(1),
    color: 'white',
    fontSize: 14,
    width: spacing(20),
    '&::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)', // Makes the calendar icon white
      opacity: 0.6,
      cursor: 'pointer'
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#3B82F6', // App's primary color
      boxShadow: '0 0 0 1px rgba(59, 130, 246, 0.5)'
    }
  },
  applyButton: {
    backgroundColor: '#3B82F6',
    color: 'white',
    border: 'none',
    borderRadius: 4,
    padding: spacing(1, 2),
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#2563EB',
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(1, 2),
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  statCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    padding: spacing(2.5), // Reduced padding
    marginTop: spacing(2),
    marginBottom: spacing(2.5), // Reduced bottom margin
  },
  statLabel: {
    fontSize: 14,
    color: 'rgba(255, 255, 255, 0.6)',
    margin: 0,
    marginBottom: spacing(1),
    textAlign: 'center',
  },
  statValue: {
    fontSize: 24, // Reduced font size
    fontWeight: 600,
    margin: 0,
    textAlign: 'center',
  },
  chartContainer: {
    height: 300, // Reduced height
    marginTop: spacing(2),
    marginBottom: spacing(2.5), // Reduced bottom margin
  },
  emptyState: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: spacing(3), // Reduced padding
  },
  note: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: spacing(2),
    marginBottom: spacing(1),
    textAlign: 'center',
    padding: spacing(0, 2),
  },
  dateRangeButton: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.7)',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
});

const FuelEfficiencyChart: React.FC<FuelEfficiencyChartProps> = ({
  records,
  mileageMetric = 'km',
  currency = '€'
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [efficiencyData, setEfficiencyData] = useState<EfficiencyDataPoint[]>([]);
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  });
  const [tempDateRange, setTempDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  });
  const [xAxisInterval, setXAxisInterval] = useState<number | 'preserveEnd'>(0);
  
  // Process data based on selected date range
  useEffect(() => {
    if (!records?.length) return;
    
    // Filter records by date range
    const dateFilteredRecords = records.filter(record => {
      const recordDate = record.datePerformed || record.dateCreated || '';
      return dayjs(recordDate).isSameOrAfter(dateRange.startDate) && 
      dayjs(recordDate).isSameOrBefore(dateRange.endDate);
    });
    
    // Filter for refueling records only
    const refuelingRecords = dateFilteredRecords
      .filter(record => record.category === 'refueling')
      .sort((a, b) => {
        const dateA = new Date(a.datePerformed || a.dateCreated || '');
        const dateB = new Date(b.datePerformed || b.dateCreated || '');
        return dateA.getTime() - dateB.getTime();
      });
    
    // Generate all months in the date range
    const monthlyData: { [key: string]: EfficiencyDataPoint } = {};
    
    // First create all month placeholders
    const startMonth = dayjs(dateRange.startDate).startOf('month');
    const endMonth = dayjs(dateRange.endDate).endOf('month');
    let currentMonth = startMonth;
    
    while (currentMonth.isBefore(endMonth) || currentMonth.isSame(endMonth, 'month')) {
      const monthKey = currentMonth.format('YYYY-MM');
      
      // Create default data point with null efficiency
      monthlyData[monthKey] = {
        date: currentMonth.format('YYYY-MM-DD'),
        efficiency: null,
        mileage: 0,
        cost: 0,
        month: currentMonth.format('MMM YYYY'),
        monthNumber: currentMonth.format('MMM YY'),
        displayMonth: `${currentMonth.month() + 1}-${currentMonth.year()}`,
        hasData: false
      };
      
      currentMonth = currentMonth.add(1, 'month');
    }
    
    // Calculate number of months and set x-axis interval
    const monthCount = Object.keys(monthlyData).length;
    if (monthCount <= 12) {
      setXAxisInterval(0); // Show every month
    } else if (monthCount <= 24) {
      setXAxisInterval(1); // Show every other month
    } else if (monthCount <= 36) {
      setXAxisInterval(2); // Show every third month
    } else {
      setXAxisInterval('preserveEnd'); // For very large ranges
    }
    
    if (refuelingRecords.length < 2) {
      // Not enough data, just use the empty placeholder months
      setEfficiencyData(Object.values(monthlyData).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
      return;
    }
    
    // First convert refueling records to a map of month -> array of refueling records
    const refuelingByMonth: { [key: string]: any[] } = {};
    
    refuelingRecords.forEach(record => {
      const date = record.datePerformed || record.dateCreated || '';
      const monthKey = dayjs(date).format('YYYY-MM');
      
      if (!refuelingByMonth[monthKey]) {
        refuelingByMonth[monthKey] = [];
      }
      
      refuelingByMonth[monthKey].push(record);
    });
    
    // Now process each month that has refueling records
    for (const monthKey of Object.keys(refuelingByMonth)) {
      const monthRecords = refuelingByMonth[monthKey];
      
      // Sort records within the month by date
      monthRecords.sort((a: { datePerformed: any; dateCreated: any; }, b: { datePerformed: any; dateCreated: any; }) => {
        const dateA = new Date(a.datePerformed || a.dateCreated || '');
        const dateB = new Date(b.datePerformed || b.dateCreated || '');
        return dateA.getTime() - dateB.getTime();
      });
      
      // If we only have one refueling record this month, we need a previous month with mileage
      if (monthRecords.length === 1) {
        const currentRecord = monthRecords[0];
        const currentMileage = Number(currentRecord.mileage || 0);
        
        if (currentMileage <= 0) continue;
        
        // Find the previous month with valid mileage
        const previousMonthKeys = Object.keys(refuelingByMonth)
          .filter(key => key < monthKey)
          .sort((a, b) => b.localeCompare(a)); // Sort in descending order
        
        if (previousMonthKeys.length === 0) continue;
        
        const previousMonthKey = previousMonthKeys[0];
        const prevMonthRecords = refuelingByMonth[previousMonthKey];
        
        if (!prevMonthRecords || prevMonthRecords.length === 0) continue;
        
        // Get the last refueling record from the previous month
        const previousRecord = prevMonthRecords[prevMonthRecords.length - 1];
        const previousMileage = Number(previousRecord.mileage || 0);
        
        if (previousMileage <= 0) continue;
        
        // Calculate distance traveled
        const distanceTraveled = currentMileage - previousMileage;
        
        // We assume title contains fuel amount for refueling records
        const fuelAmount = Number(currentRecord.title);
        
        // Skip invalid calculations
        if (distanceTraveled <= 0 || isNaN(fuelAmount) || fuelAmount <= 0) continue;
        
        // Calculate L/100km
        const efficiency = (fuelAmount * 100) / distanceTraveled;
        
        // Skip unreasonable values
        if (efficiency > 30) continue;
        
        // Update the month data
        if (monthlyData[monthKey]) {
          monthlyData[monthKey].efficiency = parseFloat(efficiency.toFixed(2));
          monthlyData[monthKey].mileage = currentMileage;
          monthlyData[monthKey].cost = Number(currentRecord.cost || 0);
          monthlyData[monthKey].hasData = true;
        }
      }
      // If we have multiple refueling records this month
      else if (monthRecords.length > 1) {
        let totalFuel = 0;
        let initialMileage = Number(monthRecords[0].mileage || 0);
        let finalMileage = Number(monthRecords[monthRecords.length - 1].mileage || 0);
        let totalCost = 0;
        
        // Skip first record for fuel calculation (it's for the previous period)
        for (let i = 1; i < monthRecords.length; i++) {
          const record = monthRecords[i];
          totalFuel += Number(record.title || 0);
          totalCost += Number(record.cost || 0);
        }
        
        const distanceTraveled = finalMileage - initialMileage;
        
        // Skip invalid calculations
        if (distanceTraveled <= 0 || totalFuel <= 0) continue;
        
        // Calculate L/100km
        const efficiency = (totalFuel * 100) / distanceTraveled;
        
        // Skip unreasonable values
        if (efficiency > 30) continue;
        
        // Update the month data
        if (monthlyData[monthKey]) {
          monthlyData[monthKey].efficiency = parseFloat(efficiency.toFixed(2));
          monthlyData[monthKey].mileage = finalMileage;
          monthlyData[monthKey].cost = totalCost;
          monthlyData[monthKey].hasData = true;
        }
      }
    }
    
    // Special processing for the latest month - if we have records in the latest month,
    // ensure they're properly included even if there's only one record
    const latestMonthKeys = Object.keys(refuelingByMonth).sort().reverse();
    if (latestMonthKeys.length >= 2) {
      const latestMonthKey = latestMonthKeys[0];
      const previousMonthKey = latestMonthKeys[1];
      
      const latestRecords = refuelingByMonth[latestMonthKey];
      const previousRecords = refuelingByMonth[previousMonthKey];
      
      // If the latest month has records but no efficiency calculated yet
      if (latestRecords && latestRecords.length > 0 && 
          (!monthlyData[latestMonthKey].hasData || monthlyData[latestMonthKey].efficiency === null)) {
        
        // Check if we have a latest month record with valid mileage
        const latestRecord = latestRecords[latestRecords.length - 1];
        const latestMileage = Number(latestRecord.mileage || 0);
        
        // And a previous month with valid mileage
        if (previousRecords && previousRecords.length > 0 && latestMileage > 0) {
          const prevRecord = previousRecords[previousRecords.length - 1];
          const prevMileage = Number(prevRecord.mileage || 0);
          
          if (prevMileage > 0 && latestMileage > prevMileage) {
            const distanceTraveled = latestMileage - prevMileage;
            const fuelAmount = Number(latestRecord.title || 0);
            
            if (distanceTraveled > 0 && fuelAmount > 0) {
              const efficiency = (fuelAmount * 100) / distanceTraveled;
              
              if (efficiency <= 30) {
                monthlyData[latestMonthKey].efficiency = parseFloat(efficiency.toFixed(2));
                monthlyData[latestMonthKey].mileage = latestMileage;
                monthlyData[latestMonthKey].cost = Number(latestRecord.cost || 0);
                monthlyData[latestMonthKey].hasData = true;
              }
            }
          }
        }
      }
    }
    
    // Convert to array and sort chronologically
    const sortedData = Object.values(monthlyData)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
    setEfficiencyData(sortedData);
  }, [records, dateRange]);

  // Calculate average fuel efficiency
  const calculateAverage = (): string => {
    if (!efficiencyData.length) return '0.00';
    
    // Filter out months with no efficiency data
    const dataWithEfficiency = efficiencyData.filter(item => item.efficiency !== null);
    if (dataWithEfficiency.length === 0) return '0.00';
    
    const sum = dataWithEfficiency.reduce((acc, item) => acc + (item.efficiency || 0), 0);
    return (sum / dataWithEfficiency.length).toFixed(2);
  };
  
  const avgEfficiency = calculateAverage();
  
  // Date range handlers
  const handleDateRangeChange = (field: keyof DateRange, value: string): void => {
    setTempDateRange(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleApplyDateRange = (): void => {
    setDateRange(tempDateRange);
    setShowDateRange(false);
  };

  const handleCancelDateRange = (): void => {
    setTempDateRange(dateRange);
    setShowDateRange(false);
  };

  const toggleDateRange = (): void => {
    setShowDateRange(!showDateRange);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2 className={classes.title}>{t('fuel_efficiency_tracking')}</h2>
        
        <div className={classes.controls}>
          {/* Date Range Button */}
          <button 
            onClick={toggleDateRange} 
            className={classes.dateRangeButton}
          >
            {t('date_range')}: {dayjs(dateRange.startDate).format('MMM DD, YYYY')} - {dayjs(dateRange.endDate).format('MMM DD, YYYY')}
          </button>
        </div>
      </div>
      
      {/* Date Range Selector - Restructured for buttons on next line */}
      {showDateRange && (
        <div className={classes.dateRangeContainer}>
          <div className={classes.dateInputsRow}>
            <div className={classes.dateInputContainer}>
              <label htmlFor="start-date" className={classes.dateLabel}>
                {t('start_date')}
              </label>
              <input
                id="start-date"
                type="date"
                className={classes.dateInput}
                value={tempDateRange.startDate}
                onChange={(e) => handleDateRangeChange('startDate', e.target.value)}
              />
            </div>
            
            <div className={classes.dateInputContainer}>
              <label htmlFor="end-date" className={classes.dateLabel}>
                {t('end_date')}
              </label>
              <input
                id="end-date"
                type="date"
                className={classes.dateInput}
                value={tempDateRange.endDate}
                onChange={(e) => handleDateRangeChange('endDate', e.target.value)}
              />
            </div>
          </div>

          <div className={classes.buttonsRow}>
            <button onClick={handleApplyDateRange} className={classes.applyButton}>
              {t('apply')}
            </button>
            <button onClick={handleCancelDateRange} className={classes.cancelButton}>
              {t('cancel')}
            </button>
          </div>
        </div>
      )}
      
      {/* Stats Card */}
      <div className={classes.statCard}>
        <p className={classes.statLabel}>{t('average_consumption')}</p>
        <h3 className={classes.statValue}>
          {avgEfficiency} L/100{mileageMetric}
        </h3>
      </div>
      
      {/* Chart */}
      <div className={classes.chartContainer}>
        {efficiencyData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={efficiencyData}
              margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis 
                dataKey="monthNumber" 
                stroke="rgba(255,255,255,0.6)"
                angle={-45}
                textAnchor="end"
                height={60}
                padding={{ left: 10, right: 10 }}
                interval={xAxisInterval} // Dynamic interval based on date range
              />            
              <YAxis 
                stroke="rgba(255,255,255,0.6)"
                label={{ 
                  value: `L/100${mileageMetric}`, 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { textAnchor: 'middle', fill: 'rgba(255,255,255,0.6)' },
                  dx: -10
                }}
                width={50}
              />
              <Tooltip 
                formatter={(value) => {
                  if (value === null || typeof value !== 'number' || value <= 0) return ['-', t('fuel_consumption')];
                  return [`${Number(value).toFixed(2)} L/100${mileageMetric}`, t('fuel_consumption')];
                }}
                labelFormatter={(label) => label}
                contentStyle={{ backgroundColor: 'rgba(0,0,0,0.8)', border: '1px solid #333' }}
              />
              <Legend wrapperStyle={{ paddingTop: '5px' }} formatter={(value) => t('fuel_consumption')} />
              <Line 
                type="monotone" 
                dataKey="efficiency" 
                stroke="#00B3A6" 
                activeDot={{ r: 6 }}
                name={t('fuel_consumption')}
                strokeWidth={2}
                connectNulls={true} // Connect across months with no data
                dot={(props) => {
                  // Only render dots for valid values (non-null)
                  const { cx, cy, value } = props;
                  if (value === null) return <></>;
                  return <circle cx={cx} cy={cy} r={4} fill="#00B3A6" />;
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className={classes.emptyState}>
            <p>{t('not_enough_fuel_data')}</p>
          </div>
        )}
      </div>
      
      <div className={classes.note}>
        {t('fuel_efficiency_note')}
      </div>
    </div>
  );
};

export default FuelEfficiencyChart;
