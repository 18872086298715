import { useEffect, useState } from 'react';
import Compressor from 'compressorjs';
import { Maybe } from 'yup/lib/types';

export const useCompressImage = (image: Maybe<File>, isDefault: boolean, uploaderIsDirty: boolean) => {
  const [compressedImage, setCompressedImage] = useState<File | null>(image as File);
  const [isOldImage, setIsOldImage] = useState<boolean>(false);

  useEffect(() => {
    // completely clean image
    if (!image) {
      setCompressedImage(null);
      setIsOldImage(false);
    }

    // do nothing if there is old image
    if (image && isDefault && !uploaderIsDirty) {
      setIsOldImage(true);
    }

    const isGif = image?.type === 'image/gif';
    // set new image
    if ((image && !isDefault) || (image && isDefault && uploaderIsDirty)) {
      setIsOldImage(false);

      if (isGif) {
        // Skip compression and use the original GIF
        setCompressedImage(image);
      } else {
        new Compressor(image, {
          quality: 0.9,
          maxWidth: 1000,
          maxHeight: 1000,
          success: (res) => {
            setCompressedImage(res as File);
          },
          error(err) {
            console.error('Image compression error:', err);
            setCompressedImage(image); // fallback to original image in case of error
          },
        });
      }
    }
  }, [image, isDefault, uploaderIsDirty]);

  return { compressedImage, isOldImage };
};
