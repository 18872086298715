export interface User {
  username: string;
  email: string;
  language: string;
  role: string;
  email_confirmed: boolean;
  date_created: string;
  tour_skipped: boolean;
  tour_finished: boolean;
}

export interface UserSettings {
  currency: string;
  date_format: string;
  update_mileage_from_record_and_story: boolean;
  is_story_audio_background_music_on: boolean;
  volume_metric: Volume;
  is_google_login_used: boolean;
  is_apple_login_used: boolean;
}

export interface UserNotifications {
  is_profile_banned?: boolean;
  is_profile_corrected?: boolean;
  is_story_banned?: boolean;
  is_comment_banned?: boolean;
  is_car_banned?: boolean;
  is_car_corrected?: boolean;
  story_got_liked?: boolean;
  story_got_disliked?: boolean;
  comment_got_liked?: boolean;
  comment_got_disliked?: boolean;
  story_got_commented?: boolean;
  comment_got_commented?: boolean;
  tagged_in_comment?: boolean;
  warning?: boolean;
  followed?: boolean;
  weekly_story?: boolean;
  system_story?: boolean;
  new_version?: boolean;
}

export interface ProfileValues {
  username: string;
  password: string;
}

export interface UsernameValues {
  username: string;
  google_id: string;
  apple_id: string;
}

export interface CarData {
  make: string;
  model: string;
  year: number;
  plate: string;
  mileage: number;
  mileage_metric: string;
  number_of_records: number;
  date_created: string;
  id: string;
  user_id: string;
  image: string | null;
  latest_fuel_type: string | null;
}

export interface Car {
  make: string;
  model: string;
  year: number;
  plate: string;
  mileage: number;
  mileageMetric: string;
  numberOfRecords: number;
  dateCreated: string;
  id: string;
  userId: string;
  image: string | null;
  currency?: string;
  volumeMetric?: Volume;
  latestFuelType: string | null;
}

export interface PublicCar {
  make: string;
  model: string;
  year: number;
  mileage: number;
  mileage_metric: string;
  image: string | null;
}
export interface PublicCarData {
  records: Record[];
  car: {
    make: string;
    model: string;
    year: number;
    plate: string;
    mileage: number;
    mileageMetric: string;
    image: string | null;
  };
}

export interface Record {
  id: string;
  carId: string;
  dateCreated: string;
  datePerformed: string;
  title: string; 
  item: string;
  category: string;
  cost: string;
  details: string;
  mileage: number;
  partName: string;
  imageOne: string | null;
  imageTwo: string | null;
  imageThree: string | null;
  imageFour: string | null;
  imageFive: string | null;
  createdAt?: string;
  groupId: string | null;
  groupTitle: string | null;
  aiType: RecordAITypeShort | 'None';
}

// export type ImageKeys = 'image_one' | 'image_two' | 'image_three' | 'image_four' | 'image_five';
export type RecordWithImages = Pick<Record, 'imageOne' | 'imageTwo' | 'imageThree' | 'imageFour' | 'imageFive'>;

export interface RecordData {
  id: string;
  car_id: string;
  title: string;
  part_name: string;
  category: string;
  cost: number;
  details: string;
  mileage: number;
  date_created: string;
  image: string | null;
}

export interface RecordsFiltersInterface {
  year: number | 'all';
  month: number | 'all';
  category: string;
}

export interface FileType {
  dataURL: string;
  file: File;
  originalFile?: File;
}

export type AssistedRecordIdentifier = { identifier: string };

export type NewRecordValues = {
  title: string;
  partName: string;
  category: string;
  cost: string;
  details: string;
  mileage: number;
  tmpId?: string;
  datePerformed: string;
};

export type RecordAITypeShort = 'itr' | 'vtr';

export type RecordAITypeFull = 'invoice' | 'voice';

export type RecordsLimits = {
  audio_record_count: number;
  audio_reminder_count: number;
  invoice_record_count: number;
  service_book_count: number;
};

export type CarMake = {
  name: string;
  query: string;
  type: string;
};

export type CarOption = {
  label: string;
  value: string;
};

export interface MappedCarMakeGroup {
  label: string;
  options: CarOption[];
}
export interface LocationData {
  country: string;
  cities: string[];
}

export interface SelectOptions {
  label: string;
  value: string;
}

export type ProfileVehiclesDisplayMode = 'show_vehicles_from_stories' | 'show' | 'hide';
export interface UserProfile {
  id: string;
  username: string;
  user_id: string;
  bio: string | null;
  country: string | null;
  city: string | null;
  date_created: string;
  image: string;
  is_trusted: boolean;
  is_patron?: boolean;
  already_following?: boolean;
  profile_vehicles_display_mode?: ProfileVehiclesDisplayMode;
}

export type Currency =
  | 'USD'
  | 'PLN'
  | 'UAH'
  | 'EUR'
  | 'CAD'
  | 'AUD'
  | 'AED'
  | 'PHP'
  | 'GBP'
  | 'NZD'
  | 'ZAR'
  | 'SGD'
  | 'MXN';

export type Volume = 'l' | 'g';

export interface Reminder {
  id?: string;
  title: string; // max 50
  category?: string; // max 50 should be
  reminder_type?: RemindType | null;
  remind_mileage?: number | string; // max 20
  to_repeat_mileage?: number | string; // max 20
  remind_before_mileage?: number | string  // max 20
  remind_date?: string;
  remind_every_date_type?: RemindEveryDateType; // "day", "week", "month", "2_months", "3_months", "6_months", "year"
  // repeat_every_date?: boolean;
  comment?: string; //note
  link?: string;
  is_completed?: boolean;
  car_id?: string;
  ai_type: ReminderAITypeShort;
}

export type ReminderAITypeShort = 'vtr' | undefined;

export type NewReminderValues = {
  category?: string; // max 50 should be
  comment?: string; //note
  link?: string;
  remindBeforeMileage?: number | string  // max 20
  remindDate?: string;
  remindEveryDateType?: RemindEveryDateType; // "day", "week", "month", "2_months", "3_months", "6_months", "year"
  // repeat_every_date?: boolean;
  remindMileage?: number | string; // max 20
  // reminderType?: RemindType | null;
  title: string; // max 50
  tmpId?: string;
  toRepeatMileage?: number | string; // max 20 
};

export type RemindType = 'date' | 'mileage' | 'date_and_mileage';
export type RemindEveryDateType =
  | 'do_not_repeat'
  | 'day'
  | 'week'
  | 'month'
  | '2_months'
  | '3_months'
  | '6_months'
  | 'year'
  | 'None';

export const remindEveryDateTypes: RemindEveryDateType[] = [
  'do_not_repeat',
  'day',
  'week',
  'month',
  '2_months',
  '3_months',
  '6_months',
  'year',
];
