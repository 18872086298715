import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { useClasses } from 'utils/hooks/useClasses';
import { useTranslation } from 'react-i18next';

// Define interfaces for type safety
interface CostRecord {
  id?: string;
  category?: string;
  cost?: string | number;
  datePerformed?: string;
  dateCreated?: string;
  [key: string]: any;
}

interface AnnualData {
  year: number;
  total: number;
  [category: string]: number;
}

interface ComparisonStats {
  yearlyChange: number;
  yearlyChangePercent: number;
  isIncrease: boolean;
}

interface AnnualCostAnalysisProps {
  records: CostRecord[];
  currency?: string;
}

// Define styles with improved layout spacing
const styles = ({ spacing, breakpoints }: { spacing: (arg0: number, arg1?: number) => string, breakpoints: any }) => ({
  root: {
    padding: spacing(3, 3), // Reduced padding
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(3), // Reduced margin
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
    },
  },
  comparisonBadgeIncrease: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(244, 67, 54, 0.2)',
    color: '#f44336',
  },
  comparisonBadgeDecrease: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(76, 175, 80, 0.2)',
    color: '#4caf50',
  },
  categoryFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(1),
    marginBottom: spacing(3), // Reduced margin
    maxHeight: 'none', // Remove max height
  },
  categoryButtonSelected: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: '#3B82F6',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#3B82F6',
    },
  },
  categoryButton: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.7)',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  chartContainer: {
    height: 300, // Reduced height
    marginTop: spacing(2),
    marginBottom: spacing(3), // Reduced bottom margin
  },
  emptyState: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  yearlyStatsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: spacing(2),
    marginTop: spacing(3), // Reduced margin
    [breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  yearCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: spacing(2), // Reduced padding
  },
  yearCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(1.5), // Reduced margin
  },
  yearTitle: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
  },
  yearBadge: {
    padding: spacing(0.25, 1),
    borderRadius: 12,
    fontSize: 12,
    backgroundColor: 'rgba(59, 130, 246, 0.2)',
    color: '#90caf9',
  },
  totalAmount: {
    fontSize: 22, // Reduced font size
    fontWeight: 600,
    marginBottom: spacing(2), // Reduced margin
  },
  categoryList: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1), // Reduced gap
  },
  categoryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(0.25, 0), // Reduced padding
  },
  categoryLabel: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '70%', // Limit width to prevent overlap
  },
  categoryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: spacing(1),
  },
  categoryValue: {
    fontWeight: 500,
  },
  colorIndicator: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    flexShrink: 0, // Prevent the color indicator from shrinking
  },
  note: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: spacing(2), // Reduced margin
    textAlign: 'center',
  },
  dateRangeContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: spacing(2),
    marginBottom: spacing(3),
    flexWrap: 'wrap',
  },
  dateInputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateLabel: {
    fontSize: '14px',
    marginBottom: '4px',
    display: 'block',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  dateInput: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(1),
    color: 'white',
    fontSize: 14,
    width: spacing(20),
    '&::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)', // Makes the calendar icon white
      opacity: 0.6,
      cursor: 'pointer'
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#3B82F6', // App's primary color
      boxShadow: '0 0 0 1px rgba(59, 130, 246, 0.5)'
    }
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-end', // Align with the bottom of inputs
    gap: spacing(1),
    height: '100%', // Take full height of the container
    paddingBottom: spacing(1), // Add a bit of padding to match input
  },
  applyButton: {
    backgroundColor: '#3B82F6',
    color: 'white',
    border: 'none',
    borderRadius: 4,
    padding: spacing(1, 2),
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#2563EB',
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(1, 2),
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
});

const AnnualCostAnalysis: React.FC<AnnualCostAnalysisProps> = ({ records, currency = '€' }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [annualData, setAnnualData] = useState<AnnualData[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(['all']);
  const [comparisonStats, setComparisonStats] = useState<ComparisonStats>({
    yearlyChange: 0,
    yearlyChangePercent: 0,
    isIncrease: false
  });

  // Extract all unique categories
  const allCategories = React.useMemo(() => {
    if (!records?.length) return [];
    
    const categories = records.reduce((acc: string[], record: CostRecord) => {
      if (record.category && !acc.includes(record.category)) {
        acc.push(record.category);
      }
      return acc;
    }, []);
    
    return ['all', ...categories];
  }, [records]);

  useEffect(() => {
    if (!records?.length) return;

    // Process data for annual costs by category
    const annualCosts: { [key: number]: AnnualData } = {};
    const categoryTotals: { [key: string]: number } = {};
    
    records.forEach(record => {
      if (!record.datePerformed && !record.dateCreated) return;
      
      const date = record.datePerformed || record.dateCreated || '';
      const year = dayjs(date).year();
      const cost = parseFloat(record.cost as string);
      const category = record.category || 'other';
      
      if (isNaN(cost)) return;
      
      // Initialize year if not exists
      if (!annualCosts[year]) {
        annualCosts[year] = { year, total: 0 };
        allCategories.filter(c => c !== 'all').forEach(c => {
          annualCosts[year][c] = 0;
        });
      }
      
      // Add cost to annual totals
      if (annualCosts[year][category] === undefined) {
        annualCosts[year][category] = 0;
      }
      annualCosts[year][category] += cost;
      annualCosts[year].total += cost;
      
      // Track category totals for sorting
      if (!categoryTotals[category]) categoryTotals[category] = 0;
      categoryTotals[category] += cost;
    });
    
    // Convert to array and sort by year
    const processedData = Object.values(annualCosts).sort((a, b) => a.year - b.year);
    
    // Calculate year-over-year change if we have multiple years
    if (processedData.length >= 2) {
      const currentYear = processedData[processedData.length - 1];
      const previousYear = processedData[processedData.length - 2];
      
      const change = currentYear.total - previousYear.total;
      const changePercent = previousYear.total ? (change / previousYear.total) * 100 : 0;
      
      setComparisonStats({
        yearlyChange: Math.abs(change),
        yearlyChangePercent: Math.abs(changePercent),
        isIncrease: change > 0
      });
    }
    
    setAnnualData(processedData);
  }, [records, allCategories]);

  const handleCategoryToggle = (category: string) => {
    if (category === 'all') {
      setSelectedCategories(['all']);
      return;
    }
    
    // If 'all' is currently selected, remove it
    let newSelection = selectedCategories.filter(c => c !== 'all');
    
    // Toggle the selected category
    if (newSelection.includes(category)) {
      newSelection = newSelection.filter(c => c !== category);
    } else {
      newSelection.push(category);
    }
    
    // If nothing is selected, default to 'all'
    setSelectedCategories(newSelection.length ? newSelection : ['all']);
  };

  // Filter data based on selected categories
  const filteredData = React.useMemo(() => {
    if (selectedCategories.includes('all')) {
      return annualData;
    }
    
    return annualData.map(yearData => {
      const filtered: AnnualData = { year: yearData.year, total: 0 };
      
      selectedCategories.forEach(category => {
        if (yearData[category] !== undefined) {
          filtered[category] = yearData[category];
          filtered.total += yearData[category];
        }
      });
      
      return filtered;
    });
  }, [annualData, selectedCategories]);

  const categoryColors: Record<string, string> = {
    maintenance: '#4299E1', // blue
    refueling: '#48BB78',   // green
    tires: '#ED8936',       // orange
    wash: '#667EEA',        // indigo
    other: '#A0AEC0',       // gray
    body: '#F56565',        // red
    lighting: '#ECC94B'     // yellow
  };

  // Custom bar chart styling including hover colors
  const getBarProps = (dataKey: string) => {
    const color = categoryColors[dataKey] || '#3B82F6';
    return {
      dataKey,
      stackId: "a",
      fill: color,
      // Use color from app's palette instead of white highlight
      activeBar: { fill: color, stroke: '#00B3A6', strokeWidth: 2 },
      name: t(`__record_category__${dataKey}`, { defaultValue: dataKey.charAt(0).toUpperCase() + dataKey.slice(1) })
    };
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2 className={classes.title}>{t('annual_cost_analysis')}</h2>
        
        {comparisonStats.yearlyChange > 0 && (
          <div className={comparisonStats.isIncrease ? classes.comparisonBadgeIncrease : classes.comparisonBadgeDecrease}>
            {comparisonStats.isIncrease ? (
              <ArrowUpRight size={16} style={{ marginRight: '4px' }} />
            ) : (
              <ArrowDownRight size={16} style={{ marginRight: '4px' }} />
            )}
            <span>{comparisonStats.yearlyChangePercent.toFixed(1)}% {t('vs_previous_year')}</span>
          </div>
        )}
      </div>
      
      <div className={classes.categoryFilters}>
        {allCategories.map(category => (
          <button
            key={category}
            className={selectedCategories.includes(category) ? classes.categoryButtonSelected : classes.categoryButton}
            onClick={() => handleCategoryToggle(category)}
          >
            {category === 'all' 
              ? t('__common__all_categories') 
              : t(`__record_category__${category}`, { defaultValue: category.charAt(0).toUpperCase() + category.slice(1) })}
          </button>
        ))}
      </div>
      
      <div className={classes.chartContainer}>
        {filteredData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={filteredData}
              margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis dataKey="year" stroke="rgba(255,255,255,0.6)" />
              <YAxis stroke="rgba(255,255,255,0.6)" />
              <Tooltip 
                formatter={(value) => [`${Number(value).toFixed(2)} ${currency}`, '']}
                labelFormatter={(value) => `${t('yearly_stats')} ${value}`}
                contentStyle={{ backgroundColor: 'rgba(30,41,59,0.9)', border: '1px solid #334155' }}
                cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }} // Custom cursor color
              />
              <Legend />
              
              {selectedCategories.includes('all') ? (
                // If 'all' is selected, show stacked bars for each category
                Object.keys(categoryColors).filter(cat => allCategories.includes(cat)).map(category => (
                  <Bar 
                    key={category}
                    {...getBarProps(category)}
                    cursor="pointer"
                  />
                ))
              ) : (
                // Otherwise show only selected categories
                selectedCategories.map(category => (
                  <Bar 
                    key={category}
                    {...getBarProps(category)}
                    cursor="pointer"
                  />
                ))
              )}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className={classes.emptyState}>
            {t('not_enough_annual_data')}
          </div>
        )}
      </div>
      
      <div className={classes.note}>
        {t('click_categories_filter')}
      </div>
    </div>
  );
};

export default AnnualCostAnalysis;
