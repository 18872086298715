import Compressor from 'compressorjs';

export const urlToObject = async (imageUrl: string): Promise<any> => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const imageTypeName = blob.type.split('/');
  const extension = imageTypeName[imageTypeName.length - 1];

  return new File([blob], `image.${extension}`, { type: blob.type });
};

export const getFileNameFromUrl = (url: string) => {
  const draftName = url.split('/').slice(-1)[0];
  return draftName.split('?response-content-type').slice(0)[0];
};

export const getFileNameClean = (filename: string) => {
  return filename.split('?response-content-type').slice(0)[0];
};

export async function getDataBlob(url: string, split = false) {
  var fileName = getFileNameFromUrl(url);
  if (split) {
    fileName = fileName.split('?')[0];
  }
  const res = await fetch(url, {
    method: 'GET',
    headers: { "Cache-Control": 'no-cache' },
    }
  );
  const blob = await res.blob();
  const pngBlob = blob.slice(0, blob.size, 'image/png');

  return new File([pngBlob as BlobPart], fileName, { type: pngBlob.type });
}

export const compressFile = (file: File) =>
  new Promise((resolve) => {
    new Compressor(file, {
      quality: 0.9,
      maxWidth: 1000,
      maxHeight: 1000,
      success: (res) => {
        resolve(res as File);
      },
    });
  });

export const compareFiles = async (defaultValue: string, fileValue: File): Promise<File> => {
  let file = fileValue;

  if (
    (!defaultValue || getFileNameFromUrl(defaultValue as string) !== fileValue.name) &&
    fileValue.type.includes('image/') &&
    !fileValue.type.includes('gif')
  ) {
    file = (await compressFile(fileValue)) as File;
  }

  return file;
};
