import React, { useEffect, useState } from 'react';
import { Fab, Theme } from '@mui/material';
import { useClasses } from './hooks/useClasses';
import { CloseRounded } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { getTKey } from './common';
import { useTranslation } from 'react-i18next';
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";

const tKey = getTKey('record_item');

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  file: File | string; // Can be a URL or File object
  onClose: () => void;
}

const styles = (theme: Theme) => ({
  pdfViewerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 1400,
    overflowY: 'scroll',
  },
  buttonContainer: {
    position: 'fixed',
    top: 10,
    right: 10,
    zIndex: 1500,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  header: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  closeButton: {
    height: 40,
    width: 40,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3),
    },
    marginTop: 'env(safe-area-inset-top)',
  },
  downloadButton: {
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

export const PDFViewer: React.FC<PDFViewerProps> = ({ file, onClose }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [pages, setPages] = useState<string[]>([]);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        let pdfSource: string | ArrayBuffer;
        if (typeof file === 'string') {
          pdfSource = file; // Use the URL directly if the file is a string
        } else {
          pdfSource = await new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
          });
        }

        const loadingTask = pdfjsLib.getDocument(pdfSource);
        const pdf = await loadingTask.promise;

        const loadedPages: string[] = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          if (context) {
            await page.render({ canvasContext: context, viewport }).promise;
            loadedPages.push(canvas.toDataURL());
          }
        }
        setPages(loadedPages);
        if (typeof file === 'string') {
          const response = await fetch(file);
          const blob = await response.blob();
          setPdfBlob(blob);
        } else {
          setPdfBlob(new Blob([pdfSource]));
        }
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [file]);

  // Disable background scrolling
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleDownload = () => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'document.pdf';
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  const portalRoot = document.getElementById('portal-root') || document.body;

  return ReactDOM.createPortal(
    <div className={classes.pdfViewerContainer}>
      <div className={classes.buttonContainer}>
        <div className={classes.header}>
          <Fab color='primary' aria-label='Close' className={classes.closeButton} onClick={onClose}>
            <CloseRounded />
          </Fab>
        </div>
        <button className={classes.downloadButton} onClick={handleDownload}>
          {t(tKey('download'))}
        </button>
      </div>
      <div>
        {pages.map((page, index) => (
          <img key={index} src={page} alt={`Page ${index + 1}`} style={{ width: '100%' }} />
        ))}
      </div>
    </div>,
    portalRoot
  );
};
