import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight, CloseRounded } from '@mui/icons-material';
import { Fab, Theme } from '@mui/material';
import Lightbox from 'react-spring-lightbox';
import { useClasses } from 'utils/hooks/useClasses';
import { convertPdfToImage } from 'utils/pdfToImage';
import { PDFViewer } from 'utils/PDFViewer';
import { getTKey } from 'utils';
import { useTranslation } from 'react-i18next';

const tKey = getTKey('record_item');

interface RecordAttachmentGalleryProps {
  images: (null | string)[]; // Assuming this can include URLs or nulls
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  attachmentImage: {
    display: 'block',
    marginRight: spacing(0.5),
    gap: spacing(0.5),
    '& img': {
      cursor: 'pointer',
      width: 90,
      height: 64,
      objectFit: 'cover',
    },
  },
  imageContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  img: {
    width: 90,
    height: 64,
    objectFit: 'cover',
    margin: spacing(2),
  },
  pdfOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Half-darken the thumbnail
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  pdfLabel: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the text
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    zIndex: 2, // Ensure it appears above the overlay
  },
  button: {
    height: 40,
    width: 45,
    [breakpoints.up('md')]: {
      margin: spacing(3),
    },
  },
  closeButton: {
    height: 40,
    width: 40,
    [breakpoints.up('md')]: {
      margin: spacing(3),
    },
    marginTop: 'env(safe-area-inset-top)',
  },
  header: {
    margin: spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  imageOverlay: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '5px',
  },
});

export const RecordAttachmentGallery: ComponentType<RecordAttachmentGalleryProps> = ({ images }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [convertedImages, setConvertedImages] = useState<{ src: string; isPDF: boolean; originalUrl: string }[]>([]);
  const [isPDFViewerOpen, setPDFViewerOpen] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState<string | null>(null);
  const [expand, setExpand] = useState(false);

  const gotoPrevious = () => currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () => currentImageIndex + 1 < convertedImages.length && setCurrentIndex(currentImageIndex + 1);

  // Convert PDFs to images
  useEffect(() => {
    const processImages = async () => {
      const processedImages = await Promise.all(
        images.map(async (url) => {
          if (url) {
            const normalizedUrl = decodeURIComponent(url.split('?')[0]);
            if (normalizedUrl.endsWith('.pdf')) {
              const image = await convertPdfToImage(url);
              return { src: image || '', isPDF: true, originalUrl: url };
            }
            return { src: url, isPDF: false, originalUrl: url };
          }
          return null;
        })
      );
      setConvertedImages(
        processedImages.filter((image): image is { src: string; isPDF: boolean; originalUrl: string } => Boolean(image))
      );
    };

    processImages();
  }, [images]);

  const imagesList = useMemo(
    () =>
      convertedImages.map((image, i) => ({
        alt: `Image ${i}`,
        src: image.src,
        isPDF: image.isPDF,
        originalUrl: image.originalUrl,
      })),
    [convertedImages]
  );

  const handleImageClick = (imageIndex: number) => {
    const clickedImage = imagesList[imageIndex];
    if (expand && clickedImage.isPDF) {
      setSelectedPDF(clickedImage.originalUrl!);
      setExpand(false); // Close Lightbox
      setPDFViewerOpen(true);
    }
  };

  return (
    <>
      <div className={classes.attachmentImage}>
        {imagesList.map((image, index) => (
          <div key={index} className={classes.imageContainer}>
            <img
              key={index}
              alt={image.alt}
              src={image.src}
              onClick={() => {
                setExpand(true);
                setCurrentIndex(index);
              }}
              className={classes.img}
            />
            {image.isPDF && (
              <>
                <div
                  className={classes.pdfOverlay}
                  onClick={() => {
                    setExpand(true);
                    setCurrentIndex(index);
                  }}
                ></div>
                <div
                  className={classes.pdfLabel}
                  onClick={() => {
                    setExpand(true);
                    setCurrentIndex(index);
                  }}
                >
                  PDF
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <Lightbox
        isOpen={expand}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        onClose={() => setExpand(false)}
        renderHeader={() => (
          <div className={classes.header}>
            <Fab color='primary' aria-label='Close' className={classes.closeButton} onClick={() => setExpand(false)}>
              <CloseRounded />
            </Fab>
          </div>
        )}
        images={imagesList.map((img) => ({
          alt: img.alt,
          src: img.src,
          onClick: () => handleImageClick(imagesList.indexOf(img)),
        }))}
        style={{ background: 'rgba(0, 0, 0, 0.87)', zIndex: 1400 }}
        currentIndex={currentImageIndex}
        renderNextButton={() => (
          <Fab color='secondary' aria-label='Next' className={classes.button} onClick={gotoNext}>
            <ChevronRight />
          </Fab>
        )}
        renderPrevButton={() => (
          <Fab color='secondary' aria-label='Previous' className={classes.button} onClick={gotoPrevious}>
            <ChevronLeft />
          </Fab>
        )}
        renderImageOverlay={() => {
          const currentImage = imagesList[currentImageIndex];
          return currentImage.isPDF ? (
            <div className={classes.imageOverlay} onClick={() => handleImageClick(currentImageIndex)}>
              {t(tKey('pdf_file_open'))}
            </div>
          ) : null;
        }}
      />
      {isPDFViewerOpen && selectedPDF && <PDFViewer file={selectedPDF} onClose={() => setPDFViewerOpen(false)} />}
    </>
  );
};
