import { Grid, Theme, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageLoader, PublicRecordsLayout } from 'components';
import { usePublicCarCurrencyQuery } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { DashboardChart, DashboardStatCard, DashboardTile, EmptyRecords } from './components';
import { usePublicDashboardPage } from './usePublicDashboardPage';
import AnnualCostAnalysis from './components/AnnualCostAnalysis';
import FuelEfficiencyChart from './components/FuelEfficiencyChart';
import CostPerDistance from './components/CostPerDistance';

const tKey = getTKey('dashboard');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  note: {
    margin: spacing(3, 0, 2),
    textAlign: 'left',
    fontSize: 12,
    opacity: 0.6,
  },
  innerWrapper: {
    margin: spacing(2, 0, 10),
    [breakpoints.up('md')]: {
      margin: spacing(6, 0),
    },
  },
  emptyRecordsContainer: {
    height: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: spacing(3),
  },
  expensesBlock: {
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: spacing(1),
    padding: spacing(3),
    marginBottom: spacing(3),
  },
  yearFilter: {
    minWidth: 120,
    marginBottom: spacing(2),
    '& .MuiInputBase-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
});

export const PublicDashboardPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const {
    carLoading: loading,
    showRecordsCharts,
    car,
    records,
    allCategories,
    allYears,
    filters,
    handleFiltersChange,
    expensesByMonth,
    totalExpenses,
    spendingByCategory,
    spendingByFrequency,
    averageMonthlyExpenses
  } = usePublicDashboardPage();
  const currentCarMileageMetric = car?.mileageMetric;
  const { identifier } = useParams<{ identifier: string }>() as { identifier: string };
  const { data: currency, isLoading: currencyLoading } = usePublicCarCurrencyQuery(identifier);

  // Handler for the year filter inside expenses section
  const handleYearChange = (event: SelectChangeEvent) => {
    handleFiltersChange({ year: Number(event.target.value) });
  };

  return (
    <PublicRecordsLayout>
      <div className={classes.innerWrapper}>
        {(loading || currencyLoading) && <PageLoader />}

        {/* Expenses Block with integrated year filter */}
        <div className={classes.expensesBlock}>
          {/* Expenses Block Header with Year Filter */}
          <div className={classes.header}>
            <h2 className={classes.title}>{t('annual_expenses_overview')}</h2>
            
            {allYears && allYears.length > 0 && (
              <FormControl size='small' className={classes.yearFilter}>
                <InputLabel htmlFor='year'>{t(tKey('form_year'))}</InputLabel>
                <Select
                  size='small'
                  id='year'
                  value={filters.year.toString()}
                  onChange={handleYearChange}
                  label={t(tKey('form_year'))}
                >
                  {allYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          
          {/* Expenses Chart */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <DashboardChart
                type='expenses'
                totalValue={totalExpenses}
                data={expensesByMonth}
                mileageMetric={currentCarMileageMetric}
                filters={filters}
                categories={allCategories}
                currency={currency}
                publicChart
                onFiltersChange={handleFiltersChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DashboardTile
                heading={`${t(tKey('average_expenses'))} (${currency})`}
                count={averageMonthlyExpenses}
              />
            </Grid>          
          </Grid>
          {showRecordsCharts && (
            <>
              <div className={classes.note}>{t(tKey('statistics_note'))}</div>
              
              {/* Category Stats - Year Filtered */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DashboardStatCard heading={t(tKey('spending_category'))} data={spendingByCategory} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardStatCard
                    color='secondary'
                    heading={t(tKey('spending_frequency'))}
                    data={spendingByFrequency}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </div>
          
        {/* Always display these charts, not affected by year filter */}
        <Grid container spacing={3}>
          {/* Annual Cost Analysis - Full History */}
          <Grid item xs={12} md={6}>
            {records ? (
              <AnnualCostAnalysis 
                records={records || []}
                currency={currency}
              />
            ) : (
              <div className={classes.emptyRecordsContainer}>
                <EmptyRecords title={t('no_records')} />
              </div>
            )}
          </Grid>

          {/* Fuel Efficiency Chart - Full History */}
          <Grid item xs={12} md={6}>
            {records?.some(r => r.category === 'refueling') ? (
              <FuelEfficiencyChart 
                records={records || []}
                mileageMetric={currentCarMileageMetric}
                currency={currency}
              />
            ) : (
              <div className={classes.emptyRecordsContainer}>
                <EmptyRecords title={t('not_enough_fuel_data')} />
              </div>
            )}
          </Grid>
        </Grid>
          
        {/* Cost Per Distance Chart - Full History */}
        <Grid container spacing={3} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <CostPerDistance 
              records={records || []}
              mileageMetric={currentCarMileageMetric}
              currency={currency}
            />
          </Grid>
        </Grid>
      </div>
    </PublicRecordsLayout>
  );
};
