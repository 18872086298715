import { ComponentType, Dispatch, SetStateAction } from 'react';

import { Delete } from '@mui/icons-material';
import { IconButton, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import ImagePlaceholder from 'assets/user-placeholder.jpeg';
import { useClasses } from 'utils/hooks/useClasses';
import { toast } from 'react-toastify';

interface ProfileImageUploaderProps {
  images: ImageListType;
  onChange: any;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    position: 'relative',
    marginBottom: spacing(2),

    '& img': {
      width: 87,
      height: 87,
      borderRadius: 99,
    },
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadedImage: {
    '&:hover img': {
      filter: 'brightness(0.6)',
    },
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: 87,

      height: 87,
      borderRadius: 99,
    },
  },
  placeholder: {
    cursor: 'pointer',
  },
  placeholderImage: {
    filter: 'contrast(0.45)',
  },
  positionCentered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  deleteButtonWrapper: {
    textAlign: 'center',
    color: palette.text.hint,
  },
  hint: {
    fontSize: 12,
    fontColor: palette.text.hint,
    lineHeight: 3,
  },
});

export const ProfileImageUploader: ComponentType<ProfileImageUploaderProps> = ({ images, onChange, setIsDirty }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const onError = (errors: any, files: any) => {
    if (errors.acceptType) {
      toast(t('image_uploader_error_unsupported_format'));
    }
  };
  return (
    <div className={classes.root}>
      <ImageUploading acceptType={['jpg', 'jpeg', 'png', 'gif']} value={images} onChange={onChange} onError={onError}>
        {({ imageList, onImageUpload, onImageRemoveAll, dragProps }) => (
          <div className={classes.wrapper}>
            {imageList.length > 0 ? (
              <div className={classes.uploadedImage}>
                {imageList.map((image, index) => (
                  <img key={index} src={image.dataURL} alt='' />
                ))}
                <div className={clsx(classes.positionCentered, classes.deleteButtonWrapper)}>
                  <IconButton
                    color='inherit'
                    onClick={() => {
                      onImageRemoveAll();
                      setIsDirty(true);
                    }}
                    size='large'
                  >
                    <Delete />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div onClick={onImageUpload} className={classes.placeholder} {...dragProps}>
                <img src={ImagePlaceholder} alt='Drag and drop' className={classes.placeholderImage} />
              </div>
            )}
            <Typography className={classes.hint}>{t('profile_picture_change')}</Typography>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
