import * as pdfjsLib from "pdfjs-dist/webpack.mjs";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export const convertPdfToImage = async (fileOrUrl: File | string): Promise<string | null> => {
  try {
    let pdfData;
    if (typeof fileOrUrl === 'string') {
      const response = await fetch(fileOrUrl);
      if (!response.ok) {
        console.error('Failed to fetch PDF:', response.statusText);
        return null;
      }
      pdfData = await response.arrayBuffer();
    } else {
      pdfData = await fileOrUrl.arrayBuffer();
    }

    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1.5 });
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const context = canvas.getContext('2d');
    await page.render({ canvasContext: context!, viewport }).promise;
    const dataUrl = canvas.toDataURL();
    return dataUrl;
  } catch (error) {
    console.error('Error converting PDF to image:', error);
    return null;
  }
};
