import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import { useClasses } from 'utils/hooks/useClasses';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useTranslation } from 'react-i18next';


dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);


// Define interfaces for type safety
interface Record {
  id?: string;
  category?: string;
  cost?: string | number;
  mileage?: string | number;
  datePerformed?: string;
  dateCreated?: string;
  [key: string]: any;
}

interface MonthlyDataPoint {
  month: string;           // YYYY-MM format
  displayMonth: string;    // Now using "Jan 24" format
  monthYear: string;       // Original "1-2024" format for backward compatibility
  cost: number;
  mileage: number;
  records: number;
  costPerDistance: number; // Always populate, use 0 for months with no data
  distance: number;        // Always populate, use 0 for months with no data
}

interface TotalStats {
  totalCost: number;
  totalDistance: number;
  costPerDistance: number;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

interface CostPerDistanceProps {
  records: Record[];
  mileageMetric?: string;
  currency?: string;
}

// Define styles
const styles = ({ spacing, breakpoints }: { spacing: (arg0: number, arg1?: number) => number, breakpoints: any }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 3), // Consistent padding
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(3),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: spacing(2),
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  select: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: `${spacing(1)}px ${spacing(2)}px`,
    color: 'white',
    fontSize: 14,
    minWidth: 120,
  },
  categoryFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(1),
    marginBottom: spacing(3),
    maxHeight: spacing(12),
    overflowY: 'auto', // Allow scroll for many categories
  },
  categoryButtonSelected: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: '#3B82F6',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#3B82F6',
    },
  },
  categoryButton: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.7)',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  dateRangeButton: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.7)',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: spacing(2),
    marginBottom: spacing(3), // Reduced margin
    [breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: spacing(1),
    },
  },
  statCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    padding: spacing(2), // Reduced padding
  },
  statLabel: {
    fontSize: 14,
    color: 'rgba(255, 255, 255, 0.6)',
    margin: 0,
    marginBottom: spacing(1),
  },
  statValue: {
    fontSize: 22, // Reduced font size
    fontWeight: 600,
    margin: 0,
  },
  chartContainer: {
    height: 300, // Reduced height
    marginTop: spacing(2),
    marginBottom: spacing(3),
  },
  emptyState: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: spacing(3), // Reduced padding
  },
  tableContainer: {
    marginTop: spacing(3), // Reduced margin
    overflowX: 'auto',
  },
  tableTitle: {
    fontSize: 18,
    fontWeight: 500,
    margin: `0 0 ${spacing(2)}px`, // Maintained margin
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  tableBody: {
    '& tr': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    },
  },
  tableHeaderLeft: {
    padding: spacing(1.5, 2),
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.6)',
    textAlign: 'left',
  },
  tableHeaderRight: {
    padding: spacing(1.5, 2),
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.6)',
    textAlign: 'right',
  },
  tableCellLeft: {
    padding: spacing(1.5, 2),
    fontSize: 14,
    textAlign: 'left',
  },
  tableCellRight: {
    padding: spacing(1.5, 2),
    fontSize: 14,
    textAlign: 'right',
  },
  note: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: spacing(2),
    marginBottom: spacing(1),
    textAlign: 'center',
  },
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    marginBottom: spacing(3),
  },
  dateInputsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(2),
  },
  buttonsRow: {
    display: 'flex',
    gap: spacing(1),
  },
  dateInputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateLabel: {
    fontSize: '14px',
    marginBottom: '4px',
    display: 'block',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  dateInput: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(1),
    color: 'white',
    fontSize: 14,
    width: spacing(20),
    '&::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)', // Makes the calendar icon white
      opacity: 0.6,
      cursor: 'pointer'
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#3B82F6', // App's primary color
      boxShadow: '0 0 0 1px rgba(59, 130, 246, 0.5)'
    }
  },
  applyButton: {
    backgroundColor: '#3B82F6',
    color: 'white',
    border: 'none',
    borderRadius: 4,
    padding: spacing(1, 2),
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#2563EB',
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 4,
    padding: spacing(1, 2),
    cursor: 'pointer',
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
});

const CostPerDistance: React.FC<CostPerDistanceProps> = ({ 
  records, 
  mileageMetric = 'km', 
  currency = '€' 
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [costData, setCostData] = useState<MonthlyDataPoint[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(['all']);
  const [totalStats, setTotalStats] = useState<TotalStats>({
    totalCost: 0,
    totalDistance: 0,
    costPerDistance: 0
  });
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  });
  const [tempDateRange, setTempDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  });

  // Extract all unique categories
  const allCategories = React.useMemo(() => {
    if (!records?.length) return ['all'];
    
    const cats = records.reduce((acc: string[], record) => {
      if (record.category && !acc.includes(record.category)) {
        acc.push(record.category);
      }
      return acc;
    }, []);
    
    return ['all', ...cats];
  }, [records]);
  
  useEffect(() => {
    if (!records?.length) return;
    
    // Filter records by date range
    const dateFilteredRecords = records.filter(record => {
      const recordDate = record.datePerformed || record.dateCreated || '';
      return dayjs(recordDate).isSameOrAfter(dateRange.startDate) && 
      dayjs(recordDate).isSameOrBefore(dateRange.endDate);
    });
    
    // Filter by category if not "all"
    const categoryFilteredRecords = selectedCategories.includes('all') 
      ? dateFilteredRecords
      : dateFilteredRecords.filter(record => selectedCategories.includes(record.category || ''));
    
    // Sort records by date
    const sortedRecords = [...categoryFilteredRecords].sort((a, b) => {
      const dateA = new Date(a.datePerformed || a.dateCreated || '');
      const dateB = new Date(b.datePerformed || b.dateCreated || '');
      return dateA.getTime() - dateB.getTime();
    });
    
    if (sortedRecords.length === 0) {
      setCostData([]);
      setTotalStats({
        totalCost: 0,
        totalDistance: 0,
        costPerDistance: 0
      });
      return;
    }
    
    // Find start and end mileage to calculate total distance
    const mileages = sortedRecords.map(r => Number(r.mileage || 0)).filter(m => m > 0);
    
    if (mileages.length < 2) {
      // Not enough mileage data
      setCostData([]);
      setTotalStats({
        totalCost: 0, 
        totalDistance: 0,
        costPerDistance: 0
      });
      return;
    }
    
    const startMileage = Math.min(...mileages);
    const endMileage = Math.max(...mileages);
    const totalDistance = endMileage - startMileage;
    
    // Calculate total cost
    const totalCost = sortedRecords.reduce((sum, record) => {
      return sum + Number(record.cost || 0);
    }, 0);
    
    // Calculate average cost per distance (will be used for months with no data)
    const avgCostPerDistance = totalDistance > 0 ? totalCost / totalDistance : 0;
    
    // Group records by month
    const monthlyData: { [key: string]: MonthlyDataPoint } = {};
    
    // First add all months in the range (even empty ones)
    const startMonth = dayjs(dateRange.startDate).startOf('month');
    const endMonth = dayjs(dateRange.endDate).endOf('month');
    let currentMonth = startMonth;
    
    while (currentMonth.isBefore(endMonth) || currentMonth.isSame(endMonth, 'month')) {
      const monthKey = currentMonth.format('YYYY-MM');
      
      monthlyData[monthKey] = {
        month: monthKey,
        displayMonth: currentMonth.format('MMM YY'),
        monthYear: `${currentMonth.month() + 1}-${currentMonth.year()}`,
        cost: 0,
        mileage: 0,
        records: 0,
        costPerDistance: 0, // Initialize to 0, will be updated if we have data
        distance: 0,        // Initialize to 0, will be updated if we have data
      };
      
      currentMonth = currentMonth.add(1, 'month');
    }
    
    // Then populate with actual record data
    sortedRecords.forEach(record => {
      if (!record.datePerformed && !record.dateCreated) return;
      
      const date = record.datePerformed || record.dateCreated || '';
      const month = dayjs(date).format('YYYY-MM');
      
      if (monthlyData[month]) {
        // Update cost
        if (record.cost) {
          monthlyData[month].cost += Number(record.cost);
        }
        
        // Update mileage to the max value within the month
        if (record.mileage && Number(record.mileage) > monthlyData[month].mileage) {
          monthlyData[month].mileage = Number(record.mileage);
        }
        
        monthlyData[month].records++;
      }
    });
    
    // Convert monthly data to array and sort by date
    let dataPoints = Object.values(monthlyData)
      .sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime());
    
    // Get all months with valid mileage data
    const monthsWithMileage = dataPoints
      .filter(point => point.mileage > 0)
      .map(point => ({ month: point.month, mileage: point.mileage }))
      .sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime());
    
    // No valid mileage data, can't calculate anything
    if (monthsWithMileage.length < 2) {
      setCostData(dataPoints.map(point => ({
        ...point,
        distance: 0,
        costPerDistance: 0
      })));
      
      setTotalStats({
        totalCost,
        totalDistance: 0,
        costPerDistance: 0
      });
      return;
    }
    
    // For months with no mileage data, interpolate between known data points
    for (let i = 0; i < dataPoints.length; i++) {
      const point = dataPoints[i];
      
      // Skip points that already have mileage data
      if (point.mileage > 0) continue;
      
      // Find the closest mileage readings before and after this month
      const before = monthsWithMileage
        .filter(m => m.month < point.month)
        .sort((a, b) => new Date(b.month).getTime() - new Date(a.month).getTime())[0];
      
      const after = monthsWithMileage
        .filter(m => m.month > point.month)
        .sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime())[0];
      
      // If we have both before and after points, interpolate
      if (before && after) {
        const beforeDate = dayjs(before.month);
        const afterDate = dayjs(after.month);
        const pointDate = dayjs(point.month);
        
        const totalDays = afterDate.diff(beforeDate, 'day');
        const daysPassed = pointDate.diff(beforeDate, 'day');
        const ratio = totalDays > 0 ? daysPassed / totalDays : 0;
        
        // Linear interpolation of mileage
        const mileageDiff = after.mileage - before.mileage;
        point.mileage = before.mileage + (mileageDiff * ratio);
      }
      // If only before points exist, use the last known mileage
      else if (before) {
        point.mileage = before.mileage;
      }
      // If only after points exist, use the first known mileage
      else if (after) {
        point.mileage = after.mileage;
      }
    }
    
    // Calculate distance and cost per distance for each month
    for (let i = 0; i < dataPoints.length; i++) {
      const current = dataPoints[i];
      
      // For the first month with mileage data
      if (i === 0) {
        // For first month, we use the global average cost per distance
        if (current.cost > 0) {
          current.costPerDistance = avgCostPerDistance;
          current.distance = 0; // We cannot calculate this for first month
        }
        continue;
      }
      
      // For all other months, calculate based on delta from previous month
      const prev = dataPoints[i-1];
      
      if (current.mileage > 0 && prev.mileage > 0) {
        const distance = current.mileage - prev.mileage;
        
        // Check if distance is positive and non-zero
        if (distance > 0) {
          current.distance = distance;
          
          // If we have costs, calculate cost per distance
          if (current.cost > 0) {
            current.costPerDistance = current.cost / distance;
          } else {
            // If no cost, set to 0
            current.costPerDistance = 0;
          }
        } else {
          // Distance is zero or negative (shouldn't happen with valid data)
          current.distance = 0;
          current.costPerDistance = 0;
        }
      } else {
        // Missing mileage data
        current.distance = 0;
        current.costPerDistance = 0;
      }
    }
    
    // Special handling for the last month's final values
    const lastPoint = dataPoints[dataPoints.length - 1];
    const secondToLastPoint = dataPoints.length > 1 ? dataPoints[dataPoints.length - 2] : null;
    
    // If the last month has cost but no valid cost per distance calculation
    if (lastPoint.cost > 0 && lastPoint.costPerDistance === 0) {
      // If we have a second-to-last point with valid cost per distance, use that
      if (secondToLastPoint && secondToLastPoint.costPerDistance > 0) {
        lastPoint.costPerDistance = secondToLastPoint.costPerDistance;
      } else {
        // Otherwise use the average
        lastPoint.costPerDistance = avgCostPerDistance;
      }
      
      // Estimate distance based on cost and cost per distance
      if (lastPoint.costPerDistance > 0) {
        lastPoint.distance = lastPoint.cost / lastPoint.costPerDistance;
      } else {
        lastPoint.distance = 0;
      }
    }
    
    // Update state
    setCostData(dataPoints);
    setTotalStats({
      totalCost,
      totalDistance,
      costPerDistance: avgCostPerDistance
    });
  }, [records, selectedCategories, dateRange]);
  
  // Category selection handlers
  const handleCategoryToggle = (category: string): void => {
    if (category === 'all') {
      setSelectedCategories(['all']);
      return;
    }
    
    // If 'all' is currently selected, remove it
    let newSelection = selectedCategories.filter(c => c !== 'all');
    
    // Toggle the selected category
    if (newSelection.includes(category)) {
      newSelection = newSelection.filter(c => c !== category);
    } else {
      newSelection.push(category);
    }
    
    // If nothing is selected, default to 'all'
    setSelectedCategories(newSelection.length ? newSelection : ['all']);
  };
  
  // Date range handlers
  const handleDateRangeChange = (field: keyof DateRange, value: string): void => {
    setTempDateRange(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleApplyDateRange = (): void => {
    setDateRange(tempDateRange);
    setShowDateRange(false);
  };

  const handleCancelDateRange = (): void => {
    setTempDateRange(dateRange);
    setShowDateRange(false);
  };

  const toggleDateRange = (): void => {
    setShowDateRange(!showDateRange);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2 className={classes.title}>
          {t('cost_per_distance', { unit: mileageMetric })}
        </h2>
        
        <div className={classes.controls}>
          {/* Date Range Button */}
          <button 
            onClick={toggleDateRange} 
            className={classes.dateRangeButton}
          >
            {t('date_range')}: {dayjs(dateRange.startDate).format('MMM DD, YYYY')} - {dayjs(dateRange.endDate).format('MMM DD, YYYY')}
          </button>
        </div>
      </div>
      
      {/* Date Range Selector - Modified structure with buttons on the next line */}
      {showDateRange && (
        <div className={classes.dateRangeContainer}>
          <div className={classes.dateInputsRow}>
            <div className={classes.dateInputContainer}>
              <label htmlFor="start-date" className={classes.dateLabel}>
                {t('start_date')}
              </label>
              <input
                id="start-date"
                type="date"
                className={classes.dateInput}
                value={tempDateRange.startDate}
                onChange={(e) => handleDateRangeChange('startDate', e.target.value)}
              />
            </div>
            
            <div className={classes.dateInputContainer}>
              <label htmlFor="end-date" className={classes.dateLabel}>
                {t('end_date')}
              </label>
              <input
                id="end-date"
                type="date"
                className={classes.dateInput}
                value={tempDateRange.endDate}
                onChange={(e) => handleDateRangeChange('endDate', e.target.value)}
              />
            </div>
          </div>

          <div className={classes.buttonsRow}>
            <button onClick={handleApplyDateRange} className={classes.applyButton}>
              {t('apply')}
            </button>
            <button onClick={handleCancelDateRange} className={classes.cancelButton}>
              {t('cancel')}
            </button>
          </div>
        </div>
      )}      
      
      {/* Category Selection */}
      <div className={classes.categoryFilters}>
        {allCategories.map(category => (
          <button
            key={category}
            className={selectedCategories.includes(category) ? classes.categoryButtonSelected : classes.categoryButton}
            onClick={() => handleCategoryToggle(category)}
          >
          {category === 'all' ? 
            t('__common__all_categories') : 
            t(`__record_category__${category}`, { defaultValue: category.charAt(0).toUpperCase() + category.slice(1) })
            }          
          </button>
        ))}
      </div>
      
      {/* Stats Cards */}
      <div className={classes.statsContainer}>
        <div className={classes.statCard}>
          <p className={classes.statLabel}>{t('total_cost')}</p>
          <h3 className={classes.statValue}>{totalStats.totalCost.toFixed(2)} {currency}</h3>
        </div>
        
        <div className={classes.statCard}>
          <p className={classes.statLabel}>{t('total_distance')}</p>
          <h3 className={classes.statValue}>{totalStats.totalDistance.toFixed(0)} {mileageMetric}</h3>
        </div>
        
        <div className={classes.statCard}>
          <p className={classes.statLabel}>{t('average_cost_per_unit', { unit: mileageMetric })}</p>
          <h3 className={classes.statValue}>{totalStats.costPerDistance.toFixed(2)} {currency}/{mileageMetric}</h3>
        </div>
      </div>
      
      {/* Chart */}
      <div className={classes.chartContainer}>
        {costData.length > 1 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={costData}
              margin={{ top: 20, right: 20, left: 20, bottom: 20 }} // Reduced margins
            >
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis 
                dataKey="displayMonth" // Use our new more readable format
                stroke="rgba(255,255,255,0.6)" 
                angle={-45} // Increase angle for better readability
                textAnchor="end" // Text alignment for rotated labels
                height={60} // Increased height to accommodate rotated labels
                padding={{ left: 10, right: 10 }} // Add padding
                interval={0} // Show all labels
              />              
              <YAxis 
                stroke="rgba(255,255,255,0.6)"
                unit={` ${currency}/${mileageMetric}`}
                width={70} // Reduced width
              />
              <Tooltip 
                formatter={(value) => {
                  if (!value || Number(value) <= 0) return ['-', ''];
                  return [`${Number(value).toFixed(4)} ${currency}/${mileageMetric}`, t('cost_per_unit', { unit: mileageMetric })];
                }}
                labelFormatter={(value) => value}
                contentStyle={{ backgroundColor: 'rgba(30,41,59,0.9)', border: '1px solid #334155' }}
              />
              <Legend formatter={(value) => t('cost_per_unit', { unit: mileageMetric })} />
              <Line 
                type="monotone" 
                dataKey="costPerDistance" 
                stroke="#3B82F6" 
                activeDot={{ r: 6 }} 
                name={t('cost_per_unit', { unit: mileageMetric })}
                strokeWidth={2}
                connectNulls={true} // Connect across null/zero values
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className={classes.emptyState}>
            <p>{t('not_enough_distance_data')}</p>
          </div>
        )}
      </div>
      
      {/* Data Table */}
      {costData.length > 0 && (
        <div className={classes.tableContainer}>
          <h3 className={classes.tableTitle}>{t('monthly_details')}</h3>
          <table className={classes.table}>
            <thead className={classes.tableHead}>
              <tr>
                <th className={classes.tableHeaderLeft}>{t('month')}</th>
                <th className={classes.tableHeaderRight}>{t('cost')} ({currency})</th>
                <th className={classes.tableHeaderRight}>{t('distance')} ({mileageMetric})</th>
                <th className={classes.tableHeaderRight}>{t('cost_per_unit', { unit: mileageMetric })}</th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              {/* Show only the latest 6 months (or fewer) within the date range */}
              {costData
                .filter(data => {
                  // Ensure we show all data within the selected date range
                  const dataDate = dayjs(data.month);
                  return dataDate.isAfter(dayjs(dateRange.startDate).startOf('month').subtract(1, 'day')) && 
                        dataDate.isBefore(dayjs(dateRange.endDate).endOf('month').add(1, 'day'));
                })
                .slice(-6) // Take last 6 months or fewer
                .reverse() // Show newest first
                .map((data) => (
                  <tr key={data.month}>
                    <td className={classes.tableCellLeft}>{data.displayMonth}</td>
                    <td className={classes.tableCellRight}>{data.cost.toFixed(2)}</td>
                    <td className={classes.tableCellRight}>{data.distance > 0 ? data.distance.toFixed(0) : '-'}</td>
                    <td className={classes.tableCellRight}>{data.costPerDistance > 0 ? data.costPerDistance.toFixed(4) : '-'}</td>
                  </tr>
                ))}
              </tbody>
          </table>
        </div>
      )}
      <div className={classes.note}>
        {t('cost_per_distance_note', { unit: mileageMetric })}
      </div>
    </div>
  );
};

export default CostPerDistance;
