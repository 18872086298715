import { Theme } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Bar, BarChart, LabelList, ResponsiveContainer } from 'recharts';
import store from 'store2';

import { getTKey, roundNumber } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { BoardFilters } from './DashboardFilters';

const tKey = getTKey('dashboard');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    padding: spacing(3, 3),
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: spacing(1),
    },
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center', 
  },
  categoryFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(1),
    marginTop: spacing(1),
    marginBottom: spacing(2),
    maxHeight: 'none', // Remove max height to show all categories
  },
  categoryButtonSelected: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: '#3B82F6',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#3B82F6',
    },
  },
  categoryButton: {
    padding: spacing(0.5, 1.5),
    borderRadius: 16,
    fontSize: 14,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.7)',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  body: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    height: 165,
    overflowX: 'auto',
    overflowY: 'hidden',

    [breakpoints.up('sm')]: {
      overflow: 'hidden',
    },
  },
  chartWrapper: {
    width: '175vw',
    height: '100%',

    [breakpoints.up('md')]: {
      width: '100%',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  totalValue: {
    display: 'block',
    fontSize: 34,
    fontWeight: 500,
    color: '#00B3A6',

    [breakpoints.up('md')]: {
      display: 'initial',
      marginLeft: spacing(2),
      color: 'inherit',
    },
  },
  hint: {
    fontSize: 12,
    marginTop: spacing(1),
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  emptyStateContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: spacing(0.5),
  },
  emptyStateText: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 16,
    textAlign: 'center',
  },
});

const CustomizedDataLabel = (props: any) => {
  const { x, y, width, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={y - 10} fill='#fff' textAnchor='middle' dominantBaseline='middle'>
        {value ? value : ''}
      </text>
    </g>
  );
};

interface DashboardChartProps {
  totalValue: number;
  data: any;
  mileageMetric: string | undefined;
  type?: 'expenses' | 'mileage';
  filters?: BoardFilters;
  categories?: string[];
  currency?: string;
  publicChart?: boolean;
  onFiltersChange?: (filters: Partial<BoardFilters>) => void;
}

type ChartPoint = { name: number | string; value: number | string };

const mapChartData = (data: number[]): ChartPoint[] => {
  let tmp = [];
  for (let i = 0; i < 12; i++) {
    if (data[i] === undefined) {
      tmp.push({ name: i, value: 0 }); // Use 0 instead of empty string
    } else {
      tmp.push({ name: i, value: roundNumber(data[i]) });
    }
  }
  return tmp;
};

// Updated CustomizedXAxisLabel to use the selected year from filters
const CustomizedXAxisLabel = (props: any) => {
  const { x, width, value, selectedYear } = props;

  return (
    <g>
      <text x={x + width / 2} y={160} fill='rgba(255, 255, 255, 0.6)' textAnchor='middle' dominantBaseline='middle'>
        {Number(value) + 1}-{selectedYear || new Date().getFullYear()}
      </text>
    </g>
  );
};

export const DashboardChart: React.FC<DashboardChartProps> = ({
  type = 'expenses',
  totalValue,
  data,
  mileageMetric,
  filters,
  categories = [],
  currency,
  publicChart,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const settingsData = store('driverbook_settings');
  const { identifier } = useParams<{ identifier: string }>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>(['all']);
  const [chartKey, setChartKey] = useState<number>(0);
  
  // Memoize chart data to prevent unnecessary recalculations
  const chartData = useMemo(() => mapChartData(data), [data]);
  
  // Check if there is data to display
  const hasData = useMemo(() => {
    return data && data.some((value: any) => value !== undefined && value > 0);
  }, [data]);

  // Reset chart when data changes to prevent jumping
  useEffect(() => {
    // When data changes, increment key to force a clean remount of the chart
    setChartKey(prevKey => prevKey + 1);
  }, [filters?.year]);

  // Handle category toggle
  const handleCategoryToggle = (category: string) => {
    let newSelection: string[];
    
    if (category === 'all') {
      newSelection = ['all'];
    } else {
      // If 'all' is currently selected, remove it
      const withoutAll = selectedCategories.filter(c => c !== 'all');
      
      // Toggle the selected category
      if (withoutAll.includes(category)) {
        newSelection = withoutAll.filter(c => c !== category);
      } else {
        newSelection = [...withoutAll, category];
      }
      
      // If nothing is selected, default to 'all'
      if (newSelection.length === 0) {
        newSelection = ['all'];
      }
    }
    
    setSelectedCategories(newSelection);
    
    // Update filters based on selection
    if (onFiltersChange) {
      const newCategory = newSelection.includes('all') ? 'all' : newSelection.join(',');
      onFiltersChange({ category: newCategory });
    }
  };

  const onClickHandler = (barData: any): void => {
    if (barData.value) {
      const monthIndex = barData.name;
      
      publicChart
        ? navigate(
            `/book/dashboard/details/${identifier}?year=${filters?.year}&month=${monthIndex}&category=${filters?.category}`
          )
        : navigate(
            `/dashboard/${filters?.carId}?year=${filters?.year}&month=${monthIndex}&category=${filters?.category}`
          );
    }
  };

  const XAxisLabelWithYear = (props: any) => (
    <CustomizedXAxisLabel {...props} selectedYear={filters?.year} />
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          {type === 'expenses'
            ? `${t(tKey('expenses'))} (${publicChart ? currency : settingsData?.currency})`
            : `${t(tKey('mileage'))} (${mileageMetric})`}
          :<span className={classes.totalValue}>{roundNumber(totalValue)}</span>
        </div>
      </div>

      {/* Category filters */}
      {type === 'expenses' && onFiltersChange && (
        <div className={classes.categoryFilters}>
          <button
            key="all"
            className={selectedCategories.includes('all') ? classes.categoryButtonSelected : classes.categoryButton}
            onClick={() => handleCategoryToggle('all')}
          >
            {t('__common__all_categories')}
          </button>
          {categories.filter(c => c !== 'all').map((category) => (
            <button
              key={category}
              className={selectedCategories.includes(category) ? classes.categoryButtonSelected : classes.categoryButton}
              onClick={() => handleCategoryToggle(category)}
            >
              {t(`__record_category__${category}`, { defaultValue: category.charAt(0).toUpperCase() + category.slice(1) })}
            </button>
          ))}
        </div>
      )}

      <div className={classes.body}>
        {hasData ? (
          <div className={classes.chartWrapper}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart 
                data={chartData} 
                margin={{ top: 20, right: 0, bottom: 20, left: 0 }} 
                key={chartKey}
              >
                <Bar
                  dataKey='value'
                  label={<CustomizedDataLabel />}
                  fill={type === 'expenses' ? '#00B3A6' : '#7F39FB'}
                  radius={[4, 4, 0, 0]}
                  onClick={onClickHandler}
                  minPointSize={4}
                  cursor="pointer"
                >
                  {/* Use the specialized component that includes selected year */}
                  <LabelList dataKey='name' content={XAxisLabelWithYear} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className={classes.emptyStateContainer}>
            <p className={classes.emptyStateText}>{t('no_data_for_selected_year')}</p>
          </div>
        )}
      </div>
    </div>
  );
};
