// Update in useDashboardPage.ts

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useCarsQuery, useRecordsByCarQuery } from 'services';
import { Car, Record } from 'types';
import {
  aggregateExpensesByMonth,
  aggregateMileageByMonth,
  aggregateSpendingByCategory,
  aggregateSpendingByFrequency,
  calcAverageMonthlyExpenses,
  calcAverageMonthlyMileage,
  getAllCategories,
  getAllYears,
  getFilteredRecordsByCar,
  getTotalExpenses,
  getTotalMileage,
} from 'utils';

import { useLocationQuery } from 'lib';
import { useNavigate } from 'react-router-dom';
import { BoardFilters } from './components/DashboardFilters';
import { Stats } from './components/DashboardStatItem';

interface DashboardPageData {
  loading: boolean;
  showFilters: boolean;
  showRecordsCharts: boolean;
  cars: Car[] | undefined;
  allYears: number[];
  allCategories: string[];
  filters: BoardFilters;
  expensesByMonth: number[];
  mileageByMonth: number[];
  averageMonthlyExpenses: number;
  averageMonthlyMileage: number;
  totalExpenses: number;
  totalMileage: number;
  spendingByCategory: Stats[];
  spendingByFrequency: Stats[];
  handleFiltersChange: (filters: Partial<BoardFilters>) => void;
  refetch: any;
  records: any;
}

type useDashboardPageType = () => DashboardPageData;
export const useDashboardPage: useDashboardPageType = () => {
  const { data: cars, isLoading: carsLoading } = useCarsQuery();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showRecordsCharts, setShowRecordsCharts] = useState<boolean>(false);
  const params = useLocationQuery();
  const navigate = useNavigate();
  const date = dayjs();
  const carId = params.get('carId') || '';
  const year = Number(params.get('year')) || date.year();
  const category = params.get('category') || 'all';
  const [filters, setFilters] = useState<BoardFilters>(() => {
    return {
      carId,
      year,
      category,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredRecordsForStats, setFilteredRecordsForStats] = useState<Record[]>([]);
  const [expensesByMonth, setExpensesByMonth] = useState<number[]>(Array(12).fill(0));
  const [mileageByMonth, setMileageByMonth] = useState<number[]>(Array(12).fill(0));
  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [totalMileage, setTotalMileage] = useState<number>(0);
  const [spendingByCategory, setSpendingByCategory] = useState<Stats[]>([]);
  const [spendingByFrequency, setSpendingByFrequency] = useState<Stats[]>([]);
  const [averageMonthlyExpenses, setAverageMonthlyExpenses] = useState<number>(0);
  const [averageMonthlyMileage, setAverageMonthlyMileage] = useState<number>(0);

  const {
    refetch,
    data: records,
    isLoading: recordsLoading,
  } = useRecordsByCarQuery(filters.carId, 'dashboard', {
    enabled: !!filters.carId,
  });

  const loading = carsLoading || recordsLoading;
  const flattenRecords = records?.flat();
  const allYears = getAllYears(flattenRecords);
  const allCategories = getAllCategories(flattenRecords);

  useEffect(() => {
    if (filters.carId && carId !== filters.carId) {
      setFilters((prev) => ({ ...prev, carId }));
      handleFiltersChange({ carId: carId, category: 'all', year: date.year() });
    } else {
      setFilters({
        carId,
        year,
        category,
      });
    }
  }, [carId, year, category]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!carsLoading && cars && cars.length) {
      const firstCarId = cars[0].id;

      if (!carId) handleFiltersChange({ carId: firstCarId });
      setShowFilters(true);
    }
  }, [cars, carsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!recordsLoading && records) {
      if (records.length) {
        // Get records filtered by both category and year for the chart
        const { filteredRecords: recordsAfterFilter } = getFilteredRecordsByCar(
          filters,
          records
        );

        // For stats cards, filter by year only, ignoring category filter
        const yearOnlyFilter = { ...filters, category: 'all' };
        const { filteredRecordsForStats: statsRecords } = getFilteredRecordsByCar(
          yearOnlyFilter,
          records
        );

        // Update filtered records
        setFilteredRecords(recordsAfterFilter);
        setFilteredRecordsForStats(statsRecords);
        
        // Calculate and update metrics for the chart
        if (recordsAfterFilter.length > 0) {
          setShowRecordsCharts(true);
          setExpensesByMonth(aggregateExpensesByMonth(recordsAfterFilter));
          setMileageByMonth(aggregateMileageByMonth(recordsAfterFilter));
          setAverageMonthlyExpenses(calcAverageMonthlyExpenses(recordsAfterFilter, filters.year));
          setAverageMonthlyMileage(calcAverageMonthlyMileage(recordsAfterFilter));
          setTotalExpenses(getTotalExpenses(recordsAfterFilter));
          setTotalMileage(getTotalMileage(recordsAfterFilter));
        } else {
          resetChartData();
          setShowRecordsCharts(true); // Keep showing the UI elements
        }
        
        // Always calculate stats based on year-only filter (ignoring category)
        if (statsRecords.length > 0) {
          setSpendingByCategory(aggregateSpendingByCategory(statsRecords));
          setSpendingByFrequency(aggregateSpendingByFrequency(statsRecords));
        } else {
          setSpendingByCategory([]);
          setSpendingByFrequency([]);
        }
      } else {
        resetChartData();
        setShowRecordsCharts(false);
      }
    }
  }, [records, recordsLoading, filters]);

  // Function to reset chart data
  const resetChartData = () => {
    setExpensesByMonth(Array(12).fill(0));
    setMileageByMonth(Array(12).fill(0));
    setAverageMonthlyExpenses(0);
    setAverageMonthlyMileage(0);
    setTotalExpenses(0);
    setTotalMileage(0);
  };

  const handleFiltersChange = (filter: Partial<BoardFilters>) => {
    navigate(
      `/dashboard?year=${filter.year ?? filters.year}&category=${filter.category ?? filters.category}&carId=${
        filter.carId ?? filters.carId
      }`
    );
  };

  return {
    loading,
    showFilters,
    showRecordsCharts,
    cars,
    allYears,
    allCategories,
    filters,
    handleFiltersChange,
    expensesByMonth,
    mileageByMonth,
    averageMonthlyExpenses,
    averageMonthlyMileage,
    totalExpenses,
    totalMileage,
    spendingByCategory,
    spendingByFrequency,
    refetch,
    records
  };
};
