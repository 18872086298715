import React, { ComponentType, useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Box, Button, FormHelperText, IconButton, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { SpinFeedLoaderMini } from 'components/SpinFeedLoader/SpinFeedLoader';
import { Record, FileType } from 'types';
import { convertPdfToImage } from 'utils/pdfToImage';
import { GlobalWorkerOptions, version } from "pdfjs-dist";

const tKey = getTKey('image_uploader');
const MAXIMUM_IMAGES_QUANTITY = 5;
const MAXIMUM_IMAGES_SIZE_IN_BYTES = 10000000;

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;

interface ImageUploaderMultipleProps {
  images: FileType[];
  onChange: (files: FileType[]) => void;
  editMode?: boolean;
  data: Record;
}

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    marginTop: spacing(1),

    '& img': {
      width: '100%',
      maxWidth: 120,
    },
  },
  title: {
    marginRight: spacing(1),
    fontSize: 16,
    fontWeight: 600,
  },
  hint: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  uploadedImage: {
    position: 'relative',
    width: '33%',
    marginRight: spacing(0.5),

    '&:hover $uploadedImageFile': {
      filter: 'brightness(0.6)',
    },
  },
  uploadedImageFile: {
    paddingBottom: '68%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  deleteButtonWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: palette.text.hint,
  },
});

const ImageUploaderMultipleComponent: ComponentType<ImageUploaderMultipleProps> = ({
  images,
  onChange,
  editMode,
  data,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [recordImageAdded, setRecordImageAdded] = useState(true);
  const [shouldRenderIcon, setShouldRenderIcon] = useState(false);
  const renderIcon = () => {
    setShouldRenderIcon(true);
  };

  const [errors, setErrors] = useState<{
    maxNumber?: boolean;
    acceptType?: boolean;
    maxFileSize?: boolean;
  }>({});

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
       
    if (!event.target.files) return;

    const selectedFiles = Array.from(event.target.files);
    event.target.value = ""; 

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    const newFiles: FileType[] = [];
    const errors = { maxNumber: false, acceptType: false, maxFileSize: false };

      for (const file of selectedFiles) {
      if (images.length + newFiles.length >= MAXIMUM_IMAGES_QUANTITY) {
        errors.maxNumber = true;
        break;
      }

      if (!['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'application/pdf'].includes(file.type)) {
        errors.acceptType = true;
        continue;
      }

      if (file.size > MAXIMUM_IMAGES_SIZE_IN_BYTES) {
        errors.maxFileSize = true;
        continue;
      }

      if (file.type === 'application/pdf') {
        const pdfImage = await convertPdfToImage(file);
        if (pdfImage) {
          // Create a new file with the image type
          const imageBlob = await fetch(pdfImage).then((res) => res.blob());
          newFiles.push({
            dataURL: pdfImage,
            file: new File([imageBlob], file.name.replace('.pdf', '.png'), { type: 'image/png' }),
            originalFile: file,
          });
        }
      } else {
        // Handle image
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataURL = e.target?.result as string;
          newFiles.push({ dataURL, file });
            if (newFiles.length === selectedFiles.length) {
            onChange([...images, ...newFiles]);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (errors.maxNumber || errors.acceptType || errors.maxFileSize) {
      setErrors(errors);
    } else {
      setErrors({});
    }
   
    if (newFiles.length) {
      onChange([...images, ...newFiles]);
    }
  };

  useEffect(() => {
    if (!editMode) return;
    const processImages = async () => {
      const isPDF = (file: FileType) => file.file.type === 'application/pdf' || file.file.name.endsWith('.pdf');
      const processFile = async (file: FileType): Promise<FileType> => {
        try {
          if (isPDF(file) && file.dataURL.startsWith('http')) {
            const dataURL = await convertPdfToImage(file.dataURL);
            return dataURL
              ? {
                  dataURL,
                  file: new File([file.file], file.file.name, { type: 'application/pdf' }),
                  originalFile: file.originalFile || file.file, 
                }
              : file;
          }

          return file;
        } catch (error) {
          console.error('Error processing file:', file.file.name, error);
          return file;
        }
      };

      const updatedImages = await Promise.all(images.map(processFile));
      if (JSON.stringify(updatedImages) !== JSON.stringify(images)) {
        onChange(updatedImages); // Only update if there are changes
      }
    };

    processImages();
  }, [editMode, images, onChange]);

  return (
    <div className={classes.root}>
      <Box display='flex' alignItems='center' mb={1.5}>
        <span className={classes.title}>{t(tKey('attachments'))}:</span>
        {images.length < MAXIMUM_IMAGES_QUANTITY && (
          <Button variant='text' color='primary' component='label'>
            {t(tKey('attach_file'))}
            <input
              ref={fileInputRef}
              type='file'
              accept='image/jpeg, image/png, image/jpg, image/gif, application/pdf'
              multiple
              hidden
              onChange={handleFileChange}
            />
          </Button>
        )}
      </Box>
      {images.length > 0 ? (
        <Box display='flex' mb={0.5}>
          {images.map((file, index) => (
            <div key={index} className={classes.uploadedImage}>
              <img src={file.dataURL} onLoad={() => renderIcon()} style={{ display: 'none' }} alt='' />
              <div
                className={classes.uploadedImageFile}
                style={{
                  backgroundImage: `url(${file.dataURL})`,
                }}
              />
              {shouldRenderIcon && (
                <div className={classes.deleteButtonWrapper}>
                  <IconButton
                    color='inherit'
                    onClick={() => {
                      const updatedFiles = [...images];
                      updatedFiles.splice(index, 1);
                      onChange(updatedFiles);
                      setRecordImageAdded(false);
                    }}
                    size='large'
                  >
                    <Delete />
                  </IconButton>
                </div>
              )}
            </div>
          ))}
        </Box>
      ) : recordImageAdded && data && data.imageOne !== '' && data.imageOne !== undefined ? (
        <SpinFeedLoaderMini loading={true} />
      ) : null}

      {Object.values(errors).some((error) => error) && (
        <FormHelperText error>
          {errors.maxNumber && <span>{t(tKey('error_number'), { maxNumber: MAXIMUM_IMAGES_QUANTITY })}</span>}
          {errors.acceptType && <span>{t(tKey('error_type'))}</span>}
          {errors.maxFileSize && (
            <span>{t(tKey('error_size'), { maxFileSize: MAXIMUM_IMAGES_SIZE_IN_BYTES / 1000000 + ' MB' })}</span>
          )}
        </FormHelperText>
      )}

      <div className={classes.hint}>{t(tKey('types_hint'))}</div>
    </div>
  );
};

export const ImageUploaderMultiple = React.memo(ImageUploaderMultipleComponent, (prevProps, nextProps) => {
  return (
    Object.is(prevProps.images, nextProps.images) &&
    prevProps.onChange === nextProps.onChange &&
    prevProps.editMode === nextProps.editMode &&
    Object.is(prevProps.data, nextProps.data)
  );
});
