// Update in usePublicDashboardPage.ts

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { Car, Record } from 'types';
import {
  aggregateExpensesByMonth,
  aggregateMileageByMonth,
  aggregateSpendingByCategory,
  aggregateSpendingByFrequency,
  calcAverageMonthlyExpenses,
  calcAverageMonthlyMileage,
  getAllCategories,
  getAllYears,
  getFilteredRecordsByCar,
  getTotalExpenses,
  getTotalMileage,
} from 'utils';

import { useLocationQuery } from 'lib';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicRecordsQuery } from 'services';
import { BoardFilters, Stats } from './components';

interface PublicDashboardPageData {
  car: Car | undefined;
  records: Record[] | undefined;
  carLoading: boolean;
  showRecordsCharts: boolean;
  allYears: number[];
  allCategories: string[];
  filters: BoardFilters;
  handleFiltersChange: (filters: Partial<BoardFilters>) => void;
  expensesByMonth: number[];
  mileageByMonth: number[];
  averageMonthlyExpenses: number;
  averageMonthlyMileage: number;
  totalExpenses: number;
  totalMileage: number;
  spendingByCategory: Stats[];
  spendingByFrequency: Stats[];
}

type usePublicDashboardPageType = () => PublicDashboardPageData;
export const usePublicDashboardPage: usePublicDashboardPageType = () => {
  const { identifier } = useParams<{ identifier: string }>() as { identifier: string };

  const { data, isLoading: carLoading } = usePublicRecordsQuery(identifier);

  const [showRecordsCharts, setShowRecordsCharts] = useState<boolean>(true);
  const params = useLocationQuery();
  const navigate = useNavigate();
  const date = dayjs();
  const { identifier: carId } = useParams<{ identifier: string }>() as { identifier: string };
  const year = Number(params.get('year')) || date.year();
  const category = params.get('category') || 'all';

  const [filters, setFilters] = useState<BoardFilters>(() => {
    return {
      carId,
      year,
      category,
    };
  });
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredRecordsForStats, setFilteredRecordsForStats] = useState<Record[]>([]);
  const [expensesByMonth, setExpensesByMonth] = useState<number[]>(Array(12).fill(0));
  const [mileageByMonth, setMileageByMonth] = useState<number[]>(Array(12).fill(0));
  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [totalMileage, setTotalMileage] = useState<number>(0);
  const [spendingByCategory, setSpendingByCategory] = useState<Stats[]>([]);
  const [spendingByFrequency, setSpendingByFrequency] = useState<Stats[]>([]);
  const [averageMonthlyExpenses, setAverageMonthlyExpenses] = useState<number>(0);
  const [averageMonthlyMileage, setAverageMonthlyMileage] = useState<number>(0);

  const flattenRecords = data?.records?.flat();
  const allYears = getAllYears(flattenRecords);
  const allCategories = getAllCategories(flattenRecords);

  useEffect(() => {
    setFilters({
      carId,
      year,
      category,
    });
  }, [carId, year, category]);

  useEffect(() => {
    if (!carLoading && data) {
      if (data.records && data.records.length) {
        // Get records filtered by both category and year for the chart
        const { filteredRecords: recordsAfterFilter } = getFilteredRecordsByCar(
          filters,
          data.records
        );

        // For stats cards, filter by year only, ignoring category filter
        const yearOnlyFilter = { ...filters, category: 'all' };
        const { filteredRecordsForStats: statsRecords } = getFilteredRecordsByCar(
          yearOnlyFilter,
          data.records
        );

        // Update filtered records
        setFilteredRecords(recordsAfterFilter);
        setFilteredRecordsForStats(statsRecords);
        
        // Calculate and update metrics for the chart
        if (recordsAfterFilter.length > 0) {
          setShowRecordsCharts(true);
          setExpensesByMonth(aggregateExpensesByMonth(recordsAfterFilter));
          setMileageByMonth(aggregateMileageByMonth(recordsAfterFilter));
          setAverageMonthlyExpenses(calcAverageMonthlyExpenses(recordsAfterFilter, filters.year));
          setAverageMonthlyMileage(calcAverageMonthlyMileage(recordsAfterFilter));
          setTotalExpenses(getTotalExpenses(recordsAfterFilter));
          setTotalMileage(getTotalMileage(recordsAfterFilter));
        } else {
          resetChartData();
          setShowRecordsCharts(true); // Keep showing the UI elements
        }
        
        // Always calculate stats based on year-only filter (ignoring category)
        if (statsRecords.length > 0) {
          setSpendingByCategory(aggregateSpendingByCategory(statsRecords));
          setSpendingByFrequency(aggregateSpendingByFrequency(statsRecords));
        } else {
          setSpendingByCategory([]);
          setSpendingByFrequency([]);
        }
      } else {
        resetChartData();
        setShowRecordsCharts(false);
      }
    }
  }, [data, carLoading, filters]);

  // Function to reset all chart data
  const resetChartData = () => {
    setExpensesByMonth(Array(12).fill(0));
    setMileageByMonth(Array(12).fill(0));
    setAverageMonthlyExpenses(0);
    setAverageMonthlyMileage(0);
    setTotalExpenses(0);
    setTotalMileage(0);
  };

  const handleFiltersChange = (filter: Partial<BoardFilters>) => {
    navigate(
      `/book/dashboard/${carId}?year=${filter.year ?? filters.year}&category=${filter.category ?? filters.category}`
    );
  };

  return {
    car: data?.car,
    records: data?.records,
    carLoading,
    showRecordsCharts,
    allYears,
    allCategories,
    filters,
    handleFiltersChange,
    expensesByMonth,
    mileageByMonth,
    averageMonthlyExpenses,
    averageMonthlyMileage,
    totalExpenses,
    totalMileage,
    spendingByCategory,
    spendingByFrequency,
  };
};
