import { Grid, Theme, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { CommonLayout, PageLoader } from 'components';
import { EmptyGarage } from 'components/Stories/EmptyGarage';
import { useInitialSettings } from 'lib';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { DashboardChart, DashboardStatCard, DashboardTile, EmptyRecords } from './components';
import { useDashboardPage } from './useDashboardPage';
import AnnualCostAnalysis from './components/AnnualCostAnalysis';
import FuelEfficiencyChart from './components/FuelEfficiencyChart';
import CostPerDistance from './components/CostPerDistance';

const tKey = getTKey('dashboard');

const styles = ({ spacing, breakpoints }: Theme) => ({
  note: {
    margin: spacing(3, 0, 2),
    textAlign: 'left',
    fontSize: 12,
    opacity: 0.6,
  },
  emptyRecordsContainer: {
    height: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: spacing(3),
  },
  expensesBlock: {
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: spacing(1),
    padding: spacing(3),
    marginBottom: spacing(3),
  },
  yearFilter: {
    minWidth: 120,
    marginBottom: spacing(2),
    '& .MuiInputBase-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
});

export const DashboardPage: ComponentType = () => {
  useInitialSettings();
  const { t } = useTranslation();
  const settingsData = store('driverbook_settings');
  const classes = useClasses(styles);
  const {
    loading,
    showFilters,
    showRecordsCharts,
    cars,
    allCategories,
    allYears,
    filters,
    handleFiltersChange,
    expensesByMonth,
    totalExpenses,
    spendingByCategory,
    spendingByFrequency,
    refetch,
    records,
    averageMonthlyExpenses,
  } = useDashboardPage();
  const currentCarMileageMetric = cars?.find((car) => car.id === filters.carId)?.mileageMetric;

  useEffect(() => {
    refetch();
  }, [refetch, totalExpenses]);

  // Handler for the year filter inside expenses section
  const handleYearChange = (event: SelectChangeEvent) => {
    handleFiltersChange({ year: Number(event.target.value) });
  };
  
  // Handler for car selection
  const handleCarChange = (event: SelectChangeEvent) => {
    handleFiltersChange({ carId: event.target.value, category: 'all' });
  };

  return (
    <CommonLayout>
      {loading && <PageLoader />}
      {!loading && (
        <>
          {showFilters ? (
            <>
              {/* Car selection only - year selection moved to expenses block */}
              {cars && cars.length > 0 && (
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size='small'>
                      <InputLabel htmlFor='car'>{t(tKey('form_car'))}</InputLabel>
                      <Select
                        size='small'
                        id='car'
                        value={filters.carId}
                        onChange={handleCarChange}
                        label={t(tKey('form_car'))}
                      >
                        {cars.map((car) => (
                          <MenuItem key={car.id} value={car.id}>
                            {car.make} {car.model}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              
              {/* Expenses Block with integrated year filter - always show this */}
              <div className={classes.expensesBlock}>
                {/* Expenses Block Header with Year Filter */}
                <div className={classes.header}>
                  <h2 className={classes.title}>{t('annual_expenses_overview')}</h2>
                  
                  {allYears && allYears.length > 0 && (
                    <FormControl size='small' className={classes.yearFilter}>
                      <InputLabel htmlFor='year'>{t(tKey('form_year'))}</InputLabel>
                      <Select
                        size='small'
                        id='year'
                        value={filters.year.toString()}
                        onChange={handleYearChange}
                        label={t(tKey('form_year'))}
                      >
                        {allYears.map(year => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                
                {/* Expenses Chart */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={9}>
                    <DashboardChart
                      type='expenses'
                      totalValue={totalExpenses}
                      data={expensesByMonth}
                      mileageMetric={currentCarMileageMetric}
                      filters={filters}
                      categories={allCategories}
                      onFiltersChange={handleFiltersChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DashboardTile
                      heading={`${t(tKey('average_expenses'))} (${settingsData.currency})`}
                      count={averageMonthlyExpenses}
                    />
                  </Grid>                
                </Grid>
                {showRecordsCharts && (
                  <>
                    <div className={classes.note}>{t(tKey('statistics_note'))}</div>
                    
                    {/* Category Stats - Year Filtered */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <DashboardStatCard heading={t(tKey('spending_category'))} data={spendingByCategory} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DashboardStatCard
                          color='secondary'
                          heading={t(tKey('spending_frequency'))}
                          data={spendingByFrequency}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>

              {/* Always display these charts, not affected by year filter */}
              <Grid container spacing={3}>
                {/* Annual Cost Analysis - Full History */}
                <Grid item xs={12} md={6}>
                  <AnnualCostAnalysis 
                    records={records}
                    currency={settingsData.currency}
                  />
                </Grid>

                {/* Fuel Efficiency Chart - Full History */}
                <Grid item xs={12} md={6}>
                  {records?.some((r: { category: string; }) => r.category === 'refueling') ? (
                    <FuelEfficiencyChart 
                      records={records}
                      mileageMetric={currentCarMileageMetric}
                      currency={settingsData.currency}
                    />
                  ) : (
                    <div className={classes.emptyRecordsContainer}>
                      <EmptyRecords title={t('not_enough_fuel_data')} />
                    </div>
                  )}
                </Grid>
              </Grid>
              
              {/* Cost Per Distance Chart - Full History */}
              <Grid container spacing={3} sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <CostPerDistance 
                    records={records}
                    mileageMetric={currentCarMileageMetric}
                    currency={settingsData.currency}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <EmptyGarage />
          )}
        </>
      )}
    </CommonLayout>
  );
};
